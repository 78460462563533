import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Flex, Select, Space, Tooltip } from 'antd';
import moment from 'moment';
import { ArrowClockwise, ArrowSquareOut, Plus, Repeat } from 'phosphor-react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useApp } from '../../AppContext';
import {
  FULL_DATE_TIME_FORMAT,
  LIST_TYPES,
  MODULES,
  ORDER,
  PAGES_SORT_FIELD,
  PAGE_TYPE_LABELS,
  REVALIDATE_FILE_CONFIG,
  ROUTES,
  STATUS_TYPES,
  WEB_ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { modalContext } from '../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import AddEditGroupModal from './component/groups/AddEditGroupModal';
import SelectGroup from './component/groups/SelectGroup';
import PreviewModal from './component/pageModules/Preview/PreviewModal';
import {
  DELETE_PAGE,
  DELETE_PAGE_GROUP,
  FORCE_PURGE_PAGE_CACHE,
  REVALIDATE_CONFIG_FILE
} from './graphql/Mutations';
import { GET_PAGES, GET_WORKSPACE_APP } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  isDefault,
  group
}) => ({
  filter: {
    limit,
    skip: offset,
    search,
    isDefault,
    pageGroupId: group?.value ?? null
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ pagesAdmin }) => ({
  data: pagesAdmin?.pages || [],
  count: pagesAdmin?.count || 0
});

const PAGE_TYPE_OPTIONS = [
  { label: 'Custom', value: false },
  { label: 'Default', value: true }
];

const Action = ({
  status,
  id,
  removeItem,
  isDefault,
  allowDelete,
  slug,
  type,
  isDeleteAllowed,
  isAddEditAllowed,
  setPageId,
  webUrl,
  isWebApp
}) => {
  const [deletePage] = useMutation(DELETE_PAGE);
  const [purgePageCache, { loading: isPurging }] = useMutation(
    FORCE_PURGE_PAGE_CACHE
  );

  const isDeleteDisable =
    (isDefault || !allowDelete) && status === STATUS_TYPES.PUBLISHED;

  const handleDelete = (e) => {
    e.stopPropagation();
    if (!isDeleteDisable)
      modalContext?.confirm({
        title: 'Are you sure, you want to delete this page?',
        centered: true,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          deletePage({
            variables: { id }
          })
            .then(({ errors }) => {
              if (removeItem && !errors) {
                removeItem(id);
              }
            })
            .catch();
        }
      });
  };

  const handleShowPreview = (e, pageId) => {
    e.preventDefault();
    e.stopPropagation();
    setPageId(pageId);
  };

  const forcePurge = (e) => {
    e.stopPropagation();
    if (slug && type) {
      purgePageCache({
        variables: {
          slug,
          type
        }
      });
    }
  };

  const url = `${webUrl}${WEB_ROUTES[type] ?? ''}/${slug?.toLowerCase()}`;

  return (
    <div className="d-flex align-center">
      {isAddEditAllowed && (
        <>
          {!isDefault && (
            <Tooltip title="Open in web">
              <Button
                className="text-btn d-flex align-center justify-center"
                type="text"
                icon={<ArrowSquareOut size={22} />}
                disabled={status !== STATUS_TYPES.PUBLISHED || !isWebApp}
                onClick={(e) => {
                  e.stopPropagation();
                  // eslint-disable-next-line no-undef
                  window?.open(url);
                }}
              />
            </Tooltip>
          )}
          <Tooltip title="Reset Cache">
            <Button
              className="text-btn d-flex align-center justify-center"
              type="text"
              icon={<ArrowClockwise size={22} />}
              onClick={forcePurge}
              loading={isPurging}
              disabled={isPurging || !slug}
            />
          </Tooltip>
        </>
      )}
      <Tooltip title="Preview">
        <Button
          className="text-btn d-flex align-center justify-center"
          type="text"
          onClick={(e) => {
            handleShowPreview(e, id);
          }}
        >
          <EyeOutlined size={20} />
        </Button>
      </Tooltip>
      {isDeleteAllowed && (
        <Tooltip
          title={
            isDeleteDisable ? 'You can not delete this page' : 'Delete page'
          }
        >
          <Button
            disabled={isDeleteDisable}
            className="text-btn"
            type="text"
            onClick={handleDelete}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export const Status = ({ status }) => {
  return (
    <div>
      {status === STATUS_TYPES.DRAFT && (
        <Badge className="status" count={status} color="#5E5957" />
      )}
      {status === STATUS_TYPES.PUBLISHED && (
        <Badge className="status" count={status} color="#52c41a" />
      )}
      {status === STATUS_TYPES.UNPUBLISHED && (
        <Badge className="status" count={status} />
      )}
    </div>
  );
};

const tableColumns = (
  { refresh, removeItem },
  isDefault,
  isAddEditAllowed,
  isDeleteAllowed,
  setPageId,
  webUrl,
  isWebApp
) => [
  {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
    width: '12%'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '12%',
    render: (text, record) => (
      <Space>
        <span>{PAGE_TYPE_LABELS[text]}</span>
        <span>
          <b>{record?.isDefault ? 'Default' : null}</b>
        </span>
      </Space>
    )
  },
  ...(!isDefault
    ? [
        {
          title: 'Slug',
          dataIndex: 'slug',
          key: 'slug'
        }
      ]
    : []),
  {
    title: 'Page Group',
    dataIndex: ['pageGroup', 'name'],
    key: 'pageGroup',
    width: 140,
    render: (value) => value ?? '-'
  },
  {
    title: 'Last modified',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '22%',
    render: (_, record) => (
      <span>{moment(record?.updatedAt).format(FULL_DATE_TIME_FORMAT)}</span>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '22%',
    render: (text, record) => <Status status={text} {...record} />
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: (_, record) => (
      <Action
        {...record}
        refresh={refresh}
        removeItem={removeItem}
        isDeleteAllowed={isDeleteAllowed}
        isAddEditAllowed={isAddEditAllowed}
        setPageId={setPageId}
        webUrl={webUrl}
        isWebApp={isWebApp}
      />
    )
  }
];
const SelectPageGroup = ({ value, onChange }) => {
  const ref = useRef(null);
  const [modalState, setModalState] = useState(null);
  const [revalidateConfigFileMutate, { loading: isRevalidating }] = useMutation(
    REVALIDATE_CONFIG_FILE
  );

  const handleSuccess = () => {
    if (ref.current?.refetch) {
      ref.current.refetch();
    }
  };

  const [deletePageGroup] = useMutation(DELETE_PAGE_GROUP, {
    onCompleted() {
      handleSuccess();
    },
    onError() {}
  });

  const handleClose = () => {
    setModalState({
      open: false,
      data: null
    });
  };

  const handleAddGroup = () => {
    setModalState({
      open: true,
      data: ''
    });
  };

  const handleDelete = (id) => {
    if (!id) return;
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this page group?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deletePageGroup({
          variables: {
            where: { id }
          }
        });
      }
    });
  };

  const revalidateConfigFile = (e) => {
    e.stopPropagation();
    revalidateConfigFileMutate({
      variables: {
        data: { moduleKey: REVALIDATE_FILE_CONFIG.PAGE_GROUP }
      }
    });
  };

  return (
    <>
      <AddEditGroupModal
        {...modalState}
        onClose={handleClose}
        onSuccess={handleSuccess}
      />
      <SelectGroup
        ref={ref}
        value={value}
        onChange={onChange}
        dropdownRender={(menu) => {
          return (
            <div>
              <div className="d-flex align-center justify-between">
                <Button
                  className="select-add-btn"
                  type="text"
                  icon={<Plus size={22} />}
                  onClick={handleAddGroup}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  Create
                </Button>
                <Button
                  type="text"
                  className="select-add-btn mr-16"
                  icon={<Repeat size={24} />}
                  onClick={revalidateConfigFile}
                  loading={isRevalidating}
                />
              </div>
              {menu}
            </div>
          );
        }}
        optionRender={(option) => (
          <Flex
            className="group-select-option"
            align="center"
            justify="space-between"
            gap={10}
          >
            <span className="mr-16">{option?.label}</span>
            <Flex align="center">
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalState({
                    open: true,
                    data: {
                      name: option?.label ?? '',
                      id: option?.value ?? ''
                    }
                  });
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              />
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(option?.value);
                }}
              />
            </Flex>
          </Flex>
        )}
      />
    </>
  );
};

function Pages({ history }) {
  const { state } = useApp();
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);
  const [group, setGroup] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [pageId, setPageId] = useState(null);
  const [purgeAllPageCache, { loading: isPurging }] = useMutation(
    FORCE_PURGE_PAGE_CACHE
  );

  const { data } = useQuery(GET_WORKSPACE_APP, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: state?.app?.id
      }
    }
  });

  const isWebApp = data?.workspaceApp?.typeKey === 'WEB_APP';

  const webUrl =
    data && isWebApp
      ? data?.workspaceApp?.url || process.env.REACT_APP_WEB_URL || ''
      : process.env.REACT_APP_WEB_URL ?? '';

  const columns = useCallback(
    (props) =>
      tableColumns(
        props,
        isDefault,
        isAddEditAllowed,
        isDeleteAllowed,
        setPageId,
        webUrl,
        isWebApp
      )?.filter((item) => item !== false),
    [isDefault, webUrl, isWebApp]
  );

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: PAGES_SORT_FIELD,
      order: ORDER[1].value,
      field: PAGES_SORT_FIELD[0].value
    }
  });

  const filters = useMemo(() => ({ ...filterProps.filters, isDefault }), [
    filterProps.filters,
    isDefault,
    group
  ]);

  const handleShowModal = () => {
    history?.push(`${ROUTES?.UI_CONFIG_PAGES}/add`, { isUpdate: false });
  };

  const handleIsDefaultChange = (val) => setIsDefault(val);

  const handleRowClick = (record) => {
    if (!isAddEditAllowed) return;

    history?.push(`${ROUTES?.UI_CONFIG_PAGES}/${record?.id}/edit`, {
      isUpdate: true
    });
  };

  const forcePurge = () => {
    purgeAllPageCache({
      variables: {
        all: true
      }
    });
  };

  const handleCancel = () => {
    setPageId(null);
  };
  const pagesBreadcrumbs = [
    { label: MODULES?.UI_CONFIGURATIONS },
    { label: MODULES?.PAGES }
  ];

  const handleGroupChange = (_, value) => setGroup(value);
  return (
    <>
      <PreviewModal open={pageId} handleCancel={handleCancel} pageId={pageId} />
      <PageHeader
        menu={pagesBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Pages' }}
        show={{ listModes: false }}
      >
        <SelectPageGroup value={group} onChange={handleGroupChange} />
        <Select
          value={isDefault}
          options={PAGE_TYPE_OPTIONS}
          onChange={handleIsDefaultChange}
        />
        <Tooltip title="Reset Cache" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={forcePurge}
            loading={isPurging}
          />
        </Tooltip>
      </FilterBar>
      <div className="ui-pages">
        <PageList
          filters={filters}
          listMode={LIST_TYPES.TABLE}
          query={GET_PAGES}
          variablesSelector={variablesSelector}
          dataSelector={dataSelector}
          TableProps={{ columns }}
          limit={30}
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record)
            };
          }}
        />
      </div>
    </>
  );
}

export default Pages;
