import { NetworkStatus, useQuery } from '@apollo/client';
import { Col, Empty, Input, Modal, Row, Spin, Tabs } from 'antd';
import { get, isEmpty, isFunction, isObject } from 'lodash';
import { MagnifyingGlass } from 'phosphor-react';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { ASSET_CATEGORY } from '../common/constants';
import InView from './InView';
import PreviewModal from './PreviewModal';

const LIMIT = 12;

const useDebounce = (value, timeout = 500) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => {
      setState(value);
    }, timeout);

    return () => clearTimeout(id);
  }, [value, timeout]);

  return state;
};

const initialPreviewModalState = {
  show: false,
  title: '',
  url: '',
  type: ASSET_CATEGORY.IMAGE
};

export const ModalContent = ({
  query,
  dataSelector,
  variablesSelector,
  renderItem,
  keyField = 'id',
  limit = LIMIT,
  keys,
  search,
  filters,
  newAsset,
  queryOptions = {},
  importRef
}) => {
  const [previewModal, setPreviewModal] = useState({
    ...initialPreviewModalState
  });

  const showPreview = useCallback((props) => {
    if (
      [ASSET_CATEGORY.TEXT, ASSET_CATEGORY.DOCUMENT].includes(props.type) &&
      props.url
    ) {
      // eslint-disable-next-line no-undef
      window?.open(props.url, 'blank');
      return;
    }
    setPreviewModal((prev) => ({
      ...prev,
      ...props,
      show: true
    }));
  }, []);

  const hidePreview = () => {
    setPreviewModal({ ...initialPreviewModalState });
  };

  useImperativeHandle(importRef, () => ({ showPreview }), [showPreview]);

  const debouncedSearch = useDebounce(search?.trim());

  const { fetchMore, data, networkStatus, refetch } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: variablesSelector(0, limit, debouncedSearch, filters),
    ...queryOptions
  });

  const records = useMemo(() => (data ? dataSelector(data) : []), [data]);
  const count = useMemo(
    () => (data && isObject(keys) ? get(data, [keys.data, keys.count], 0) : 0),
    [data]
  );

  const hasMoreData = count > records.length;

  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  useEffect(() => {
    if (!isEmpty(newAsset)) {
      refetch();
    }
  }, [newAsset]);

  const fetchMoreRecords = useCallback(
    ({ offset, search: searchValue, filters: filtersValue } = {}) => {
      fetchMore({
        variables: variablesSelector(offset, limit, searchValue, filtersValue),
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            fetchMoreResult &&
            keys &&
            isObject(keys) &&
            'data' in keys &&
            'records' in keys &&
            'count' in keys
          ) {
            return {
              ...prev,
              [keys.data]: {
                ...get(prev, keys.data),
                [keys.records]: [
                  ...get(prev, [keys.data, keys.records]),
                  ...get(fetchMoreResult, [keys.data, keys.records])
                ],
                [keys.count]: get(fetchMoreResult, [keys.data, keys.count])
              }
            };
          }
          return { ...prev };
        }
      });
    },
    [fetchMore]
  );

  return (
    <>
      <PreviewModal {...previewModal} onClose={hidePreview} />
      <div className="selectable-modal-body">
        {loading ? (
          <div className="selectable-modal-wrapper">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {!records?.length ? (
              <div className="selectable-modal-wrapper">
                <Empty />
              </div>
            ) : (
              <>
                <Row gutter={[16, 16]}>
                  {records?.map((record) => (
                    <Col
                      xs={12}
                      xl={4}
                      lg={6}
                      md={8}
                      key={
                        isFunction(keyField)
                          ? keyField(record)
                          : record[keyField]
                      }
                    >
                      {renderItem(record, {
                        showPreview
                      })}
                    </Col>
                  ))}
                </Row>
                {isFetchingMore && (
                  <div className="d-flex justify-center pt-16">
                    <Spin />
                  </div>
                )}
                {!isFetchingMore && hasMoreData && !loading && (
                  <InView
                    onChange={({ inView }) => {
                      if (inView) {
                        fetchMoreRecords({
                          offset: records.length,
                          search,
                          filters
                        });
                      }
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const SelectableModal = ({
  open,
  onClose,
  title = 'Select Record',
  filters,
  renderFilters,
  setShowAssetModal,
  newAsset,
  isAssetEditAllowed,
  categoryKey,
  createForm,
  progressLoading,
  ...rest
}) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!open) setSearch('');
  }, [open]);

  const handleSearchChange = (e) => {
    setSearch(e?.target?.value);
  };

  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      title={
        <div className="d-flex justify-between align-center">
          <p>{!isAssetEditAllowed && title}</p>
        </div>
      }
      onCancel={handleClose}
      width="95%"
      centered
      className="selectable-modal"
      footer={null}
      destroyOnClose
      maskClosable={!progressLoading}
      closable={!progressLoading}
    >
      {isAssetEditAllowed ? (
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: title,
              children: (
                <>
                  <Row className="mb-12">
                    <Col xs={8}>
                      <Input
                        placeholder="Search..."
                        allowClear
                        className="search-component"
                        name="search"
                        value={search}
                        onChange={handleSearchChange}
                        prefix={<MagnifyingGlass size={20} />}
                      />
                    </Col>
                    <Col xs={16} className="d-flex justify-end align-center">
                      {renderFilters &&
                        isFunction(renderFilters) &&
                        renderFilters({ search })}
                    </Col>
                  </Row>
                  <ModalContent
                    filters={filters}
                    search={search}
                    newAsset={newAsset}
                    {...rest}
                  />
                </>
              )
            },
            ...(createForm
              ? createForm?.map((item) => ({
                  ...item
                }))
              : [])
          ]}
        />
      ) : (
        <>
          <Row className="mb-12">
            <Col xs={8}>
              <Input
                placeholder="Search..."
                allowClear
                className="search-component"
                name="search"
                value={search}
                onChange={handleSearchChange}
                prefix={<MagnifyingGlass size={20} />}
              />
            </Col>
            <Col xs={16} className="d-flex justify-end align-center">
              {renderFilters &&
                isFunction(renderFilters) &&
                renderFilters({ search })}
            </Col>
          </Row>
          {open && (
            <ModalContent
              filters={filters}
              search={search}
              newAsset={newAsset}
              {...rest}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default SelectableModal;
