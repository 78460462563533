import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space
} from 'antd';
import dayjs from 'dayjs';
import { isEmpty, isObject, filter as lodashFilter, map } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import * as urlSlug from 'url-slug';
import videoPlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  DOMAIN_STATUS,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  VIDEO_LIVE_STATUS,
  VIDEO_LIVE_STATUS_OPTIONS,
  VIDEO_RESOURCES_TYPES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import {
  GET_TAGS,
  GET_TOPICS,
  GET_VIDEO,
  GET_VIDEO_RELATED_DATA
} from './graphql/Queries';

import AudioPlaceholder from '../../assets/images/audioPlaceholder.jpg';
import { formValidatorRules } from '../../common/utils';
import AllowedPlansTable from '../../components/AllowedPlansTable';
import LoadEditor from '../../components/LoadEditor';
import Portal from '../../components/Portal';
import SeoInput, {
  defaultSeoValues,
  getSeoFormFields
} from '../../components/SeoInput';
import useCheckPermission from '../../hooks/useCheckPermission';
import SelectAsset, { initialAsset } from '../assets/components/SelectAsset';
import { SlugInput } from '../labels/topics/components/FormInputs';
import {
  Permissions,
  SelectSources,
  Switch
} from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select, SelectContributor } from './components/FormInputs';
import { CREATE_VIDEO, UPDATE_VIDEO } from './graphql/Mutations';

const initialResources = {
  [VIDEO_RESOURCES_TYPES.QUESTIONS]: '',
  [VIDEO_RESOURCES_TYPES.QUOTES]: '',
  [VIDEO_RESOURCES_TYPES.SCRIPTURE]: '',
  [VIDEO_RESOURCES_TYPES.LINKS]: ''
};
const initialValues = {
  title: '',
  description: '',
  slug: '/',
  tags: [],
  topics: [],
  status: STATUS_TYPES.DRAFT,
  contentRating: '',
  speakers: [],
  video: {
    ...initialAsset,
    isLive: false,
    status: null
  },
  image: {
    ...initialAsset
  },
  imageThumbnail: {
    ...initialAsset
  },
  videoThumbnail: {
    ...initialAsset
  },
  timelineThumbnail: {
    ...initialAsset
  },
  sources: [],
  documents: [],
  audios: [],
  tracks: [],
  metaHeader: '',
  metaFooter: '',
  resources: {
    ...initialResources
  },
  html: '',
  permissions: [],
  liveStatus: VIDEO_LIVE_STATUS.PENDING,
  isLive: false,
  liveUrl: '',
  seo: defaultSeoValues
};

const resources = [
  { label: 'Questions', name: VIDEO_RESOURCES_TYPES.QUESTIONS },
  { label: 'Quotes', name: VIDEO_RESOURCES_TYPES.QUOTES },
  { label: 'Scripture', name: VIDEO_RESOURCES_TYPES.SCRIPTURE },
  { label: 'Links', name: VIDEO_RESOURCES_TYPES.LINKS }
];

const VIDEO_DESCRIPTION_MAX_LENGTH = 2000;

const AddEditVideo = ({ history, match: { params } }) => {
  const [form] = Form.useForm();
  const { videoId } = params;
  const [videoStatus, setVideoStatus] = useState(STATUS_TYPES.DRAFT);
  const [selectedTopics, setSelectedTopics] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const topicsIds = Form.useWatch('topics', form);
  const isEdit = !!videoId;
  const videoData = Form.useWatch('video', form);
  const image = Form.useWatch('image', form) ?? initialValues?.image;
  const imageThumbnail = Form.useWatch('imageThumbnail', form);
  const tracks = Form.useWatch('tracks', form);
  const tags = Form.useWatch('tags', form);

  const videoBreadcrumbs = [
    { label: MODULES?.CONTENTS },
    { label: MODULES?.VIDEOS, route: ROUTES?.CONTENTS_VIDEOS },
    videoId && { label: videoId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const isLive = Form.useWatch('isLive', form);

  const [
    fetchVideoDetails,
    { loading: fetchingDetails, data: { videoAdmin } = {} }
  ] = useLazyQuery(GET_VIDEO, {
    fetchPolicy: 'network-only'
  });

  const [
    videoRelatedDataMutate,
    { loading: videoRelatedDataLoading, data: videoRelatedData }
  ] = useLazyQuery(GET_VIDEO_RELATED_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (
        res?.getVideoRelatedData?.image &&
        (videoAdmin?.autoSyncAssetDetailConfig?.image || !isEdit)
      ) {
        form.setFieldValue('image', {
          id: res?.getVideoRelatedData?.image?.id ?? '',
          url: res?.getVideoRelatedData?.image?.url ?? ''
        });
      }
      if (
        res?.getVideoRelatedData?.imageThumbnail &&
        (videoAdmin?.autoSyncAssetDetailConfig?.imageThumbnail || !isEdit)
      ) {
        form.setFieldValue('imageThumbnail', {
          id: res?.getVideoRelatedData?.imageThumbnail?.id ?? '',
          url: res?.getVideoRelatedData?.imageThumbnail?.url ?? ''
        });
      }
      if (
        !isEmpty(res?.getVideoRelatedData?.captions) &&
        (videoAdmin?.autoSyncAssetDetailConfig?.captions || !isEdit)
      ) {
        form.setFieldValue(
          'tracks',
          res?.getVideoRelatedData?.captions?.map(({ id, url, title }) => ({
            id,
            url,
            title
          })) ?? []
        );
      }
    }
  });

  const isSyncedImage =
    image?.id === videoRelatedData?.getVideoRelatedData?.image?.id;

  const isSyncedImageThumbnail =
    imageThumbnail?.id ===
    videoRelatedData?.getVideoRelatedData?.imageThumbnail?.id;

  const isSyncedTracks = tracks?.some((selectedTrack) =>
    videoRelatedData?.getVideoRelatedData?.captions?.some(
      (singleGeneratedTrack) => selectedTrack?.id === singleGeneratedTrack?.id
    )
  );

  useEffect(() => {
    if (videoAdmin) {
      setVideoStatus(videoAdmin?.status);
    }
  }, [videoAdmin, setVideoStatus]);

  useEffect(() => {
    if (videoData?.status === DOMAIN_STATUS?.READY) {
      videoRelatedDataMutate({
        variables: {
          where: {
            id: videoData?.id
          }
        }
      });
    }
  }, [videoData]);
  const [addUpdateVideo, { loading }] = useMutation(
    isEdit ? UPDATE_VIDEO : CREATE_VIDEO
  );

  useEffect(() => {
    if (isEdit && !!videoId) {
      fetchVideoDetails({
        variables: {
          id: videoId
        }
      }).then((res) => {
        const video = res.data?.videoAdmin;
        if (video) {
          const slug = video?.slug.startsWith('/')
            ? video?.slug
            : `/${video?.slug}`;
          form.setFieldsValue({
            title: video?.title ?? '',
            description: video?.description ?? '',
            slug,
            permissions:
              video?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            tags:
              video?.tags?.map((tag) => ({
                label: tag?.name,
                value: tag?.id
              })) ?? [],
            topics:
              video?.topics?.length > 0
                ? map(video?.topics, (item) => {
                    return {
                      value: item?.id,
                      label: item?.name
                    };
                  })
                : [],
            status: video?.status ?? STATUS_TYPES.DRAFT,
            contentRating: video?.contentRating ?? '',
            liveStatus: video?.liveStatus ?? '',
            speakers:
              video?.speakers?.map((speaker) => ({
                id: speaker?.id ?? '',
                firstName: speaker?.firstName ?? '',
                lastName: speaker?.lastName ?? '',
                imageURL: speaker?.image?.url ?? '',
                color: speaker?.primaryColor ?? ''
              })) ?? [],
            video: {
              id: video?.video?.id ?? '',
              url:
                video?.video?.serviceVideoThumbnail ??
                video?.video?.serviceImageThumbnail ??
                videoPlaceholder,
              isLive: video?.video?.isLive,
              status: video?.video?.status
            },
            previewVideo: video?.previewVideo
              ? {
                  id: video?.previewVideo?.id,
                  url:
                    video?.previewVideo?.serviceVideoThumbnail ??
                    video?.previewVideo?.serviceImageThumbnail ??
                    '',
                  title: video?.previewVideo?.title
                }
              : {},
            image: {
              id: video?.autoSyncAssetDetailConfig?.image
                ? videoRelatedData?.getVideoRelatedData?.image?.id
                : video?.image?.id,
              url: video?.autoSyncAssetDetailConfig?.image
                ? videoRelatedData?.getVideoRelatedData?.image?.url
                : video?.image?.url
            },
            autoSyncAssetDetailConfig: {
              captions: !isEmpty(video?.autoSyncAssetDetailConfig?.captions),
              imageThumbnail: video?.autoSyncAssetDetailConfig?.imageThumbnail,
              image: video?.autoSyncAssetDetailConfig?.image
            },
            imageThumbnail: {
              id: video?.autoSyncAssetDetailConfig?.imageThumbnail
                ? videoRelatedData?.getVideoRelatedData?.imageThumbnail?.id
                : video?.imageThumbnail?.id || '',
              url: video?.autoSyncAssetDetailConfig?.imageThumbnail
                ? videoRelatedData?.getVideoRelatedData?.imageThumbnail?.url
                : video?.imageThumbnail?.url || ''
            },
            videoThumbnail: {
              id: video?.videoThumbnail?.id ?? '',
              url: video?.videoThumbnail?.url ?? ''
            },
            timelineThumbnail: {
              id: video?.timelineThumbnail?.id ?? '',
              url: video?.timelineThumbnail?.url ?? ''
            },
            documents:
              video?.documents?.map(({ id, url, title }) => ({
                id,
                url,
                title
              })) ?? [],
            html: video?.html ?? '',
            audios:
              video?.audioTracks?.map(
                ({ id, serviceImageThumbnail, title }) => ({
                  id,
                  url: serviceImageThumbnail ?? AudioPlaceholder,
                  title
                })
              ) ?? [],
            tracks: video?.autoSyncAssetDetailConfig?.captions
              ? videoRelatedData?.getVideoRelatedData?.captions?.map(
                  ({ id, url, title }) => ({
                    id,
                    url,
                    title
                  })
                ) || []
              : video?.textTracks?.map(({ id, url, title }) => ({
                  id,
                  url,
                  title
                })) || [],
            sources:
              video?.sources?.map((source) => ({
                id: source?.id,
                title: source?.name,
                url: source?.image?.url
              })) || [],
            metaHeader: video?.metaHeader ?? '',
            metaFooter: video?.metaFooter ?? '',
            resources: (video?.resources ?? [])?.reduce(
              (acc, data) => {
                if (data?.content) {
                  acc[VIDEO_RESOURCES_TYPES[data?.type]] = data?.content;
                }
                return acc;
              },
              { ...initialResources }
            ),
            allowedPlans:
              video?.allowedPlans?.map((item) => ({
                ...item,
                key: { label: item?.key, value: item?.key },
                expiredAt: item?.expiredAt ? dayjs(item?.expiredAt) : null
              })) ?? [],
            seo: getSeoFormFields(video?.seo)
          });
        }
      });
    }
  }, [isEdit, videoId, form, fetchVideoDetails]);

  const handleCancel = () => {
    history.replace(ROUTES?.CONTENTS_VIDEOS);
  };

  const handleSubmit = async () => {
    await form?.validateFields();
    const data = form?.getFieldsValue();
    const payload = {
      title: data?.title || '',
      description: data?.description || '',
      slug: data?.slug?.startsWith('/') ? data?.slug?.substring(1) : data?.slug,
      speakers:
        data?.speakers?.map((speaker, index) => {
          return {
            contributorId: speaker?.id,
            order: index + 1
          };
        }) ?? [],
      tags:
        data?.tags?.map((item, index) => {
          return {
            tagId: item?.value,
            order: index + 1
          };
        }) || [],
      topics:
        data?.topics?.length > 0
          ? map(data?.topics, (item, index) => {
              return {
                topicId: item?.value,
                order: index + 1
              };
            })
          : [],
      status: videoStatus,
      liveStatus: data?.liveStatus || VIDEO_LIVE_STATUS.PENDING,
      ...(!isEdit && {
        isLive: data?.video?.isLive ?? false
      }),
      contentRating: data?.contentRating || '',
      imageId: data?.image?.id || null,
      imageThumbnailId: data?.imageThumbnail?.id || null,
      videoThumbnailId: data?.videoThumbnail?.id || null,
      timelineThumbnailId: data?.timelineThumbnail?.id || null,
      ...(data?.isLive
        ? {
            isLive: true,
            liveUrl: data?.liveUrl
          }
        : {
            videoId: data?.video?.id || null,
            previewId: data?.previewVideo?.id || null
          }),
      audioTrackIds: data?.audios?.map(({ id }) => id),
      textTrackIds: data?.tracks?.map(({ id }) => id),
      html: data?.html ?? '',
      documentIds: data?.documents?.map(({ id }) => id),
      sources: data?.sources?.map(({ id }, index) => ({
        sourceId: id,
        order: index + 1
      })),
      metaHeader: data?.metaHeader || '',
      metaFooter: data?.metaFooter || '',
      resources: Object.entries(VIDEO_RESOURCES_TYPES).map(([key, name]) => ({
        type: key,
        content: data?.resources?.[name] ?? ''
      })),
      permissions: data?.permissions?.map(({ value }) => value),
      allowedPlans: data?.allowedPlans?.map(({ key, expiredAt }) => ({
        key: isObject(key) ? key?.value : key,
        expiredAt: expiredAt ? dayjs(expiredAt) : null
      })),
      autoSyncAssetDetailConfig: {
        captions: isSyncedTracks,
        imageThumbnail: isSyncedImageThumbnail,
        image: isSyncedImage
      },
      seo: data?.seo
    };

    addUpdateVideo({
      variables: {
        data: payload,
        ...(isEdit && {
          id: videoId
        })
      }
    }).then(() => {
      history.push(ROUTES?.CONTENTS_VIDEOS);
    });
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const onChangeStatus = async (statusValue) => {
    await form?.validateFields();
    const data = form?.getFieldsValue();
    const payload = {
      title: data?.title || '',
      description: data?.description || '',
      slug: data?.slug?.startsWith('/') ? data?.slug?.substring(1) : data?.slug,
      speakers:
        data?.speakers?.map((speaker, index) => {
          return {
            contributorId: speaker?.id,
            order: index + 1
          };
        }) ?? [],
      tags:
        data?.tags?.map((item, index) => {
          return {
            tagId: item?.value,
            order: index + 1
          };
        }) || [],
      topics:
        data?.topics?.length > 0
          ? map(data?.topics, (item, index) => {
              return {
                topicId: item?.value,
                order: index + 1
              };
            })
          : [],
      status: statusValue,
      liveStatus: data?.liveStatus || VIDEO_LIVE_STATUS.PENDING,
      ...(!isEdit && {
        isLive: data?.video?.isLive ?? false
      }),
      contentRating: data?.contentRating || '',
      imageId: data?.image?.id || null,
      imageThumbnailId: data?.imageThumbnail?.id || null,
      videoThumbnailId: data?.videoThumbnail?.id || null,
      timelineThumbnailId: data?.timelineThumbnail?.id || null,
      ...(data?.isLive
        ? {
            isLive: true,
            liveUrl: data?.liveUrl
          }
        : {
            videoId: data?.video?.id || null
          }),
      audioTrackIds: data?.audios?.map(({ id }) => id),
      textTrackIds: data?.tracks?.map(({ id }) => id),
      html: data?.html ?? '',
      documentIds: data?.documents?.map(({ id }) => id),
      sources: data?.sources?.map(({ id }, index) => ({
        sourceId: id,
        order: index + 1
      })),
      metaHeader: data?.metaHeader || '',
      metaFooter: data?.metaFooter || '',
      resources: Object.entries(VIDEO_RESOURCES_TYPES).map(([key, name]) => ({
        type: key,
        content: data?.resources?.[name] ?? ''
      })),
      permissions: data?.permissions?.map(({ value }) => value),
      allowedPlans: data?.allowedPlans?.map(({ key, expiredAt }) => ({
        key: isObject(key) ? key?.value : key,
        expiredAt: expiredAt ? dayjs(expiredAt) : null
      })),
      autoSyncAssetDetailConfig: {
        captions: isSyncedTracks,
        imageThumbnail: isSyncedImageThumbnail,
        image: isSyncedImage
      },
      seo: data?.seo
    };

    addUpdateVideo({
      variables: {
        data: payload,
        ...(isEdit && {
          id: videoId
        })
      }
    }).then((res) => {
      setVideoStatus(statusValue);
      const id = res?.data?.createVideo?.video?.id;
      if (!isEdit) {
        history.push(`${ROUTES?.CONTENTS_VIDEOS}/${id}/edit`);
      }
    });
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const defaultFilters = useMemo(
    () => ({
      isLive: videoAdmin?.isLive
    }),
    [videoAdmin]
  );

  return (
    <>
      <Portal portalId="page-header-portalId">
        <div className="d-flex align-center">
          <AntdSelect
            options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
              ({ name, value }) => ({
                label: name,
                value
              })
            )}
            value={videoStatus}
            onChange={onChangeStatus}
            placeholder="Select status"
            popupMatchSelectWidth={false}
            disabled={loading || fetchingDetails || videoRelatedDataLoading}
            className="page-header-select"
          />
          <div className="d-flex button-section mb-12 ml-8 justify-end">
            <Space>
              {isAddEditAllowed && (
                <Button
                  disabled={
                    loading || fetchingDetails || videoRelatedDataLoading
                  }
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              )}

              <Button disabled={loading} onClick={handleCancel}>
                Cancel
              </Button>
            </Space>
          </div>
        </div>
      </Portal>
      <PageHeader menu={videoBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter title!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <Input
                    placeholder="Enter title"
                    onChange={handleTitleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(VIDEO_DESCRIPTION_MAX_LENGTH)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE),
                    formValidatorRules?.slug()
                  ]}
                >
                  <SlugInput />
                </Form.Item>
                <Form.Item
                  label="Speakers"
                  name="speakers"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (value?.length < 1) {
                          throw new Error(
                            'Please select at least one speaker!'
                          );
                        }
                      }
                    }
                  ]}
                >
                  <SelectContributor
                    displayText="Speaker"
                    disabled={isViewOnly}
                    multiple
                  />
                </Form.Item>
                <Form.Item name="tags" label="Tags">
                  <Select
                    mode="multiple"
                    placeholder="Select tags"
                    query={GET_TAGS}
                    variablesSelector={(filter) => ({
                      filter: {
                        ...filter,
                        priorityIds: selectedTags
                      }
                    })}
                    dataSelector={(data) =>
                      data?.tagsAdmin?.tags?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? []
                    }
                    keys={{
                      data: 'tagsAdmin',
                      records: 'tags',
                      count: 'count'
                    }}
                    onClose={(isOpen) => {
                      if (!isOpen) {
                        setSelectedTags(map(tags, (item) => item?.value));
                      }
                    }}
                    onDeselect={(values) => {
                      setSelectedTags(
                        map(
                          lodashFilter(
                            tags,
                            (item) => item?.value !== values?.value
                          ),
                          (item) => item?.value
                        )
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="topics"
                  label="Topic"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.length) {
                          throw new Error('Please select topic!');
                        }
                      }
                    }
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select topic"
                    query={GET_TOPICS}
                    variablesSelector={(filter) => ({
                      filter: {
                        ...filter,
                        priorityIds: selectedTopics
                      }
                    })}
                    dataSelector={(data) =>
                      data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? 0
                    }
                    keys={{
                      data: 'topicsAdmin',
                      records: 'topics',
                      count: 'count'
                    }}
                    onClose={(isOpen) => {
                      if (!isOpen) {
                        setSelectedTopics(
                          map(topicsIds, (item) => item?.value)
                        );
                      }
                    }}
                    onDeselect={(values) => {
                      setSelectedTopics(
                        map(
                          lodashFilter(
                            topicsIds,
                            (item) => item?.value !== values?.value
                          ),
                          (item) => item?.value
                        )
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item name="sources" label="Sources">
                  <SelectSources />
                </Form.Item>
                <Form.Item label="Publish Status" name="status">
                  <AntdSelect
                    options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                      ({ name, value }) => ({
                        label: name,
                        value
                      })
                    )}
                    placeholder="Select publish status"
                  />
                </Form.Item>
                <Form.Item label="Live Status" name="liveStatus">
                  <AntdSelect
                    options={VIDEO_LIVE_STATUS_OPTIONS}
                    placeholder="Select Live status"
                  />
                </Form.Item>
                <Form.Item
                  name="contentRating"
                  label="Content Rating"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter content rating" />
                </Form.Item>

                {!isEdit && (
                  <Form.Item name="isLive" valuePropName="checked">
                    <Switch label="Do you have a livestream video URL?" />
                  </Form.Item>
                )}
                {isLive ? (
                  <Form.Item
                    name="liveUrl"
                    label={`Live Stream HLS URL "M3U8"`}
                    required
                    rules={[
                      formValidatorRules?.required('Please enter URL!'),
                      {
                        type: 'url',
                        message: 'Please enter valid URL!'
                      }
                    ]}
                  >
                    <Input type="url" placeholder="Enter url" />
                  </Form.Item>
                ) : (
                  <>
                    <Form.Item
                      label="Video"
                      name="video"
                      required
                      rules={[
                        {
                          async validator(_, value) {
                            if (value.id === '') {
                              throw new Error('Please select video!');
                            }
                          }
                        }
                      ]}
                    >
                      <SelectAsset
                        disabled={isViewOnly}
                        modalTitle="Select Video"
                        categoryKey={ASSET_CATEGORY.VIDEO}
                        btnText="Video"
                        dataSelector={({
                          id,
                          serviceImageThumbnail,
                          serviceVideoThumbnail,
                          isLive: live,
                          url,
                          status
                        }) => ({
                          id,
                          url:
                            serviceVideoThumbnail ||
                            serviceImageThumbnail ||
                            url ||
                            videoPlaceholder,
                          isLive: live,
                          status
                        })}
                        showLive
                        {...(isEdit && {
                          disableFilters: true,
                          defaultFilters
                        })}
                        onChange={() => {
                          if (
                            videoAdmin?.autoSyncAssetDetailConfig?.image ===
                            true
                          ) {
                            form?.resetFields(['image']);
                          }
                          if (
                            videoAdmin?.autoSyncAssetDetailConfig
                              ?.imageThumbnail === true
                          ) {
                            form?.resetFields(['imageThumbnail']);
                          }
                          if (
                            videoAdmin?.autoSyncAssetDetailConfig?.tracks ===
                            true
                          ) {
                            form?.resetFields(['tracks']);
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Preview Video" name="previewVideo">
                      <SelectAsset
                        disabled={isViewOnly}
                        modalTitle="Select Preview Video"
                        categoryKey={ASSET_CATEGORY.VIDEO}
                        btnText="Preview Video"
                        dataSelector={({
                          id,
                          serviceImageThumbnail,
                          serviceVideoThumbnail
                        }) => ({
                          id,
                          url: serviceVideoThumbnail || serviceImageThumbnail
                        })}
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  label={
                    <span>
                      Image
                      <Switch
                        wrapperClassName={!isSyncedImage && 'mt-8'}
                        label="Auto Sync"
                        onChange={(checked) => {
                          if (checked) {
                            form?.setFieldValue('image', {
                              id:
                                videoRelatedData?.getVideoRelatedData?.image
                                  ?.id,
                              url:
                                videoRelatedData?.getVideoRelatedData?.image
                                  ?.url
                            });
                          } else {
                            form?.resetFields(['image']);
                          }
                        }}
                        checked={isSyncedImage}
                        disabled={
                          !videoRelatedData?.getVideoRelatedData?.image?.id
                        }
                      />
                    </span>
                  }
                  name="image"
                  extra="Recommended size  (2500 * 1242)"
                >
                  <SelectAsset
                    disabled={
                      isViewOnly ||
                      videoData?.status !== DOMAIN_STATUS?.READY ||
                      isSyncedImage
                    }
                    modalTitle="Select Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Image Thumbnail
                      <Switch
                        wrapperClassName={!isSyncedImageThumbnail && 'mt-8'}
                        label="Auto Sync"
                        onChange={(checked) => {
                          if (checked) {
                            form?.setFieldValue('imageThumbnail', {
                              id:
                                videoRelatedData?.getVideoRelatedData
                                  ?.imageThumbnail?.id,
                              url:
                                videoRelatedData?.getVideoRelatedData
                                  ?.imageThumbnail?.url
                            });
                          } else {
                            form?.resetFields(['imageThumbnail']);
                          }
                        }}
                        checked={isSyncedImageThumbnail}
                        disabled={
                          !videoRelatedData?.getVideoRelatedData?.imageThumbnail
                            ?.id
                        }
                      />
                    </span>
                  }
                  name="imageThumbnail"
                  extra="Recommended size  (590 * 330)"
                >
                  <SelectAsset
                    disabled={
                      isViewOnly ||
                      videoData?.status !== DOMAIN_STATUS?.READY ||
                      isSyncedImageThumbnail
                    }
                    modalTitle="Select Image Thumbnail"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image Thumbnail"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Video Thumbnail"
                  name="videoThumbnail"
                  extra="Recommended size  (590 * 330)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Video Thumbnail"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Video Thumbnail"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Text Tracks
                      <Switch
                        wrapperClassName={!isSyncedTracks && 'mt-8'}
                        label="Auto Sync"
                        onChange={(checked) => {
                          if (checked) {
                            form?.setFieldValue(
                              'tracks',
                              videoRelatedData?.getVideoRelatedData?.captions?.map(
                                ({ id, url, title }) => ({
                                  id,
                                  url,
                                  title
                                })
                              )
                            );
                          } else {
                            form?.resetFields(['tracks']);
                          }
                        }}
                        checked={isSyncedTracks}
                        disabled={isEmpty(
                          videoRelatedData?.getVideoRelatedData?.captions
                        )}
                      />
                    </span>
                  }
                  name="tracks"
                >
                  <SelectAsset
                    disabled={
                      isViewOnly ||
                      videoData?.status !== DOMAIN_STATUS?.READY ||
                      isSyncedTracks
                    }
                    multiple
                    modalTitle="Select Tracks"
                    btnText="Tracks"
                    categoryKey={ASSET_CATEGORY.TEXT}
                    dataSelector={({ id, url, title }) => ({
                      id,
                      url,
                      title
                    })}
                  />
                </Form.Item>
                <Form.Item label="Audios" name="audios">
                  <SelectAsset
                    disabled={isViewOnly}
                    multiple
                    modalTitle="Select Audios"
                    btnText="Audios"
                    categoryKey={ASSET_CATEGORY.AUDIO}
                    dataSelector={({ id, serviceImageThumbnail, title }) => ({
                      id,
                      url: serviceImageThumbnail,
                      title
                    })}
                  />
                </Form.Item>
                <Form.Item label="Documents" name="documents">
                  <SelectAsset
                    disabled={isViewOnly}
                    multiple
                    modalTitle="Select Documents"
                    btnText="Documents"
                    categoryKey={ASSET_CATEGORY.DOCUMENT}
                    dataSelector={({ id, url, title }) => ({
                      id,
                      url,
                      title
                    })}
                  />
                </Form.Item>

                {resources.map(({ label, name }) => (
                  <Form.Item
                    key={name}
                    name={['resources', name]}
                    label={label}
                    rules={[
                      formValidatorRules?.maxLength(
                        4000,
                        'Max length of 4000 character exceeded'
                      )
                    ]}
                  >
                    <LoadEditor disabled={isViewOnly} className="full-html" />
                  </Form.Item>
                ))}

                <Form.Item
                  name="metaHeader"
                  label="Meta Header"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta header" />
                </Form.Item>

                <Form.Item
                  name="metaFooter"
                  label="Meta Footer"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta footer" />
                </Form.Item>
                <Permissions />
                <fieldset className="mb-12">
                  <legend className="role-legend">Allowed Plans</legend>
                  <AllowedPlansTable
                    loading={fetchingDetails}
                    form={form}
                    formKey="allowedPlans"
                  />
                </fieldset>
                <SeoInput form={form} />
              </Col>
              <Col md={24} lg={12}>
                <Form.Item className="full-html" label="Html" name="html">
                  <LoadEditor disabled={isViewOnly} className="full-html" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditVideo;
