import { gql } from '@apollo/client';

export const GET_FORMS = gql`
  query FormsAdmin($filter: FormsFilter, $sort: FormsSort) {
    formsAdmin(filter: $filter, sort: $sort) {
      count
      forms {
        id
        description
        status
        title
        type
        slug
      }
    }
  }
`;

export const GET_FORM_TEMPLATE = gql`
  query FormTemplateAdmin($where: FormTemplateWhereUniqueInput!) {
    formTemplateAdmin(where: $where) {
      messages {
        type
        message
      }
      ctas {
        label
        type
        action
        actionUrl
        icon {
          id
          title
        }
      }
      fields {
        key
        label
        type
        required
        order
        options {
          key
          label
          order
        }
      }
      type
    }
  }
`;

export const GET_FORM = gql`
  query FormAdmin($where: FormWhereUniqueInput!) {
    formAdmin(where: $where) {
      id
      title
      slug
      description
      type
      status
      fields {
        key
        label
        type
        required
        order
        options {
          key
          label
          order
        }
      }
      ctas {
        label
        type
        action
        actionUrl
        icon {
          id
          url
        }
      }
      permissions
      metaData
      metaHeader
      metaFooter
      messages {
        type
        message
      }
      createdBy {
        id
        firstName
        lastName
      }
      order
      html
      allowedPlans {
        key
        expiredAt
      }
      seo
    }
  }
`;

export const FORM_RESPONSES = gql`
  query UserFormResponsesAdmin(
    $where: FormWhereUniqueInput!
    $filter: UserFormResponsesFilter
  ) {
    userFormResponsesAdmin(where: $where, filter: $filter) {
      count
      formResponses
    }
  }
`;
