import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Image, Space, Tag, theme, Typography } from 'antd';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import AudioPlaceholder from '../../../assets/images/audioPlaceholder.jpg';
import DocumentPlaceholder from '../../../assets/images/docPlaceholder.png';
import videoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  PROVIDER_TYPES,
  SELECTABLE_MODAL_LIMIT,
  STATUS_COLORS
} from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import SelectableModal from '../../../components/SelectableModal';
import { useModal } from '../../videos/components/FormInputs';
import Preview from '../../videos/components/Preview';
import AddAudioModal from '../audio/AddAudioModal';
import CommonDocumentForm from '../document/CommonDocumentForm';
import {
  GET_ASSETS,
  GET_VIDEOS_BY_WORKSPACE_PLATFORM
} from '../graphql/Queries';
import CommonIconForm from '../icons/CommonIconForm';
import CommonImageForm from '../images/CommonImageForm';
import CommonTextForm from '../text/CommonTextForm';
import AddVideoModalForm from '../videos/AddVideoModalForm';
import ImportVideoModal from '../videos/ImportVideoModal';
import CreateAssetModal from './CreateAssetModal';

export const initialAsset = {
  id: '',
  url: ''
};

export const DEFAULT_PLACEHOLDER_IMAGE = {
  [ASSET_CATEGORY.VIDEO]: videoPlaceholder,
  [ASSET_CATEGORY.IMAGE]: videoPlaceholder,
  [ASSET_CATEGORY.DOCUMENT]: DocumentPlaceholder,
  [ASSET_CATEGORY.TEXT]: DocumentPlaceholder,
  [ASSET_CATEGORY.AUDIO]: AudioPlaceholder
};

export const getAssetImage = (item, key) =>
  !item
    ? DEFAULT_PLACEHOLDER_IMAGE[key]
    : {
        [ASSET_CATEGORY.VIDEO]:
          item?.serviceVideoThumbnail ?? item?.serviceImageThumbnail,
        [ASSET_CATEGORY.IMAGE]:
          getImageUrl(item?.url, {
            height: 200,
            width: 200
          }) || videoPlaceholder,
        [ASSET_CATEGORY.ICON]: item?.url || videoPlaceholder,
        [ASSET_CATEGORY.AUDIO]: item?.serviceImageThumbnail ?? AudioPlaceholder
      }[key] ?? DEFAULT_PLACEHOLDER_IMAGE[key];

const platformVideosVariableSelector = (offset, limit, search, filters) => ({
  filter: {
    skip: offset,
    limit,
    search
  },
  where: {
    id: filters.platform,
    type: PROVIDER_TYPES.VIDEO
  }
});

const SelectAsset = ({
  id,
  value,
  onChange,
  dataSelector,
  modalTitle,
  categoryKey,
  btnText,
  multiple = false,
  previewImageKey = '',
  allowClear = true,
  isAssetEditAllowed = true,
  isAssetViewAllowed = true,
  disabled = false,
  IsTableView = false,
  customButton = false,
  assetRef,
  contentType = null,
  isAssetEdit = false,
  loading
}) => {
  const { colorText } = theme.useToken().token;
  const [selectedIds, setSelectedIds] = useState([]);

  const { open, openModal, closeModal } = useModal();
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [newAsset, setNewAsset] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [progressLoading, setProgressLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setSelectedIds(value);
    } else {
      setSelectedIds([]);
    }
  }, [open]);

  useImperativeHandle(
    assetRef,
    () => {
      return {
        imageClick() {
          openModal();
        }
      };
    },
    []
  );

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value.find((items) => items.id === item.id);
      if (isPresent) {
        onChange(value.filter((items) => items.id !== item.id));
      } else {
        onChange([...value, dataSelector(item)]);
      }
      return;
    }
    onChange(dataSelector(item));
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value.filter((items) => items.id !== item.id));
    } else {
      onChange({ ...initialAsset });
    }
  };

  const renderForms = () => {
    if (categoryKey === ASSET_CATEGORY.IMAGE) {
      return [
        {
          key: '2',
          label: 'Upload Image',
          children: (
            <CommonImageForm
              isModal
              setNewAsset={setNewAsset}
              isAssetEdit={isAssetEdit}
              setShowModal={setShowAssetModal}
              closeModal={closeModal}
              setProgressLoading={setProgressLoading}
            />
          )
        }
      ];
    }
    if (categoryKey === ASSET_CATEGORY.VIDEO) {
      return [
        {
          key: '2',
          label: 'Upload Video',
          children: (
            <AddVideoModalForm
              isModal
              setNewAsset={setNewAsset}
              setShowModal={setShowAssetModal}
              closeModal={closeModal}
              setProgressLoading={setProgressLoading}
            />
          )
        },
        {
          key: '3',
          label: 'Import Video',
          children: (
            <ImportVideoModal
              isModal
              open
              newAsset={setNewAsset}
              query={GET_VIDEOS_BY_WORKSPACE_PLATFORM}
              variablesSelector={platformVideosVariableSelector}
              dataSelector={(data) =>
                data?.getVideosByWorkspacePlatform?.platformVideos || []
              }
              queryOptions={{
                skip: !platform
              }}
              keys={{
                data: 'getVideosByWorkspacePlatform',
                records: 'platformVideos',
                count: 'count'
              }}
              setPlatform={setPlatform}
              platform={platform}
              categoryKey={categoryKey}
              closeModal={closeModal}
              handleCardSelect={handleCardSelect}
              setProgressLoading={setProgressLoading}
              progressLoading={progressLoading}
            />
          )
        }
      ];
    }
    if (categoryKey === ASSET_CATEGORY.DOCUMENT) {
      return [
        {
          key: '2',
          label: 'Upload Document',
          children: (
            <CommonDocumentForm
              isModal
              setNewAsset={setNewAsset}
              isAssetEdit={isAssetEdit}
              setShowModal={setShowAssetModal}
              closeModal={closeModal}
              setProgressLoading={setProgressLoading}
            />
          )
        }
      ];
    }
    if (categoryKey === ASSET_CATEGORY.AUDIO) {
      return [
        {
          key: '2',
          label: 'Upload Audio',
          children: (
            <AddAudioModal
              isModal
              setNewAsset={setNewAsset}
              isAssetEdit={isAssetEdit}
              setShowModal={setShowAssetModal}
              closeModal={closeModal}
              setProgressLoading={setProgressLoading}
            />
          )
        }
      ];
    }
    if (categoryKey === ASSET_CATEGORY.TEXT) {
      return [
        {
          key: '2',
          label: 'Upload Tracks',
          children: (
            <CommonTextForm
              isModal
              setNewAsset={setNewAsset}
              isAssetEdit={isAssetEdit}
              setShowModal={setShowAssetModal}
              closeModal={closeModal}
              setProgressLoading={setProgressLoading}
            />
          )
        }
      ];
    }
    if (categoryKey === ASSET_CATEGORY.ICON) {
      return [
        {
          key: '2',
          label: 'Upload Icon',
          children: (
            <CommonIconForm
              isModal
              setNewAsset={setNewAsset}
              isAssetEdit={isAssetEdit}
              setShowModal={setShowAssetModal}
              closeModal={closeModal}
              setProgressLoading={setProgressLoading}
            />
          )
        }
      ];
    }
  };

  useEffect(() => {
    if (!isEmpty(newAsset)) {
      if (newAsset?.asset) {
        if (multiple) {
          const isPresent = value.find(
            (items) => items.id === newAsset?.data.id
          );
          if (isPresent) {
            onChange(value.filter((items) => items.id !== newAsset?.data.id));
          } else {
            onChange([...value, dataSelector(newAsset?.data)]);
          }
        } else {
          onChange(dataSelector(newAsset?.data));
        }
      }
    }
  }, [newAsset]);

  return (
    <>
      {showAssetModal && (
        <CreateAssetModal
          showModal={showAssetModal}
          setShowModal={setShowAssetModal}
          assetType={categoryKey}
          setNewAsset={setNewAsset}
          isAssetEdit={isAssetEdit}
        />
      )}
      <SelectableModal
        title={modalTitle}
        open={open}
        onClose={closeModal}
        query={GET_ASSETS}
        categoryKey={categoryKey}
        variablesSelector={(offset, limit, search) => ({
          filter: {
            categoryKey,
            limit: SELECTABLE_MODAL_LIMIT,
            skip: offset,
            search,
            ...((!isEmpty(selectedIds?.id) || isArray(selectedIds)) && {
              priorityIds:
                selectedIds?.id || selectedIds?.map((item) => item?.id) || []
            }),
            ...(contentType && {
              contentType
            })
          }
        })}
        dataSelector={(data) => data?.assets?.assets || []}
        keys={{
          data: 'assets',
          records: 'assets',
          count: 'count'
        }}
        isAssetEditAllowed={isAssetEditAllowed}
        newAsset={newAsset}
        setShowAssetModal={setShowAssetModal}
        createForm={renderForms()}
        progressLoading={progressLoading}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value.map((val) => val.id).includes(item.id)
            : value?.id === item?.id;

          return (
            <Card
              className={`selectable-modal-card cs-card ${
                isSelected ? 'active selected-item' : ''
              }`}
              cover={
                <>
                  {isSelected && (
                    <span className="checked-icon">
                      <CheckOutlined />
                    </span>
                  )}
                  <Image
                    preview={false}
                    className="label-poster"
                    src={getAssetImage(item, categoryKey)}
                    alt=""
                  />
                </>
              }
              onClick={() => handleCardSelect(item)}
            >
              <Card.Meta
                title={
                  <span title={item.title} className="label-title">
                    {item.title}
                  </span>
                }
                description={
                  <div className="d-flex justify-between">
                    <Typography.Paragraph
                      className="asset-description"
                      ellipsis={{ rows: 2 }}
                    >
                      {moment(item.createdAt).format('ll')}
                    </Typography.Paragraph>
                    {item?.status && (
                      <Typography.Paragraph
                        className="asset-description tag-typography"
                        ellipsis={{ rows: 2 }}
                      >
                        <Tag
                          className="ml-8 custom-tag"
                          color={STATUS_COLORS[item?.status]}
                        >
                          {item?.status}
                        </Tag>
                      </Typography.Paragraph>
                    )}
                  </div>
                }
              />
            </Card>
          );
        }}
      />
      <Space wrap>
        {!loading && (
          <>
            {multiple ? (
              <>
                {value?.length > 0 &&
                  value?.map((item) => (
                    <Preview
                      {...(allowClear && {
                        onRemove: () => handleRemove(item)
                      })}
                      IsTableView={IsTableView}
                      key={item.id}
                      bgImg={
                        categoryKey === ASSET_CATEGORY.AUDIO
                          ? item.url ?? DEFAULT_PLACEHOLDER_IMAGE[categoryKey]
                          : item[previewImageKey] ??
                            DEFAULT_PLACEHOLDER_IMAGE[categoryKey]
                      }
                    >
                      <Preview.Title color={colorText}>
                        {item.title}
                      </Preview.Title>
                    </Preview>
                  ))}
              </>
            ) : (
              <>
                {!!value?.id && (
                  <Preview
                    IsTableView={IsTableView}
                    {...(allowClear && {
                      onRemove: handleRemove
                    })}
                    bgImg={
                      categoryKey === ASSET_CATEGORY.DOCUMENT
                        ? DEFAULT_PLACEHOLDER_IMAGE[categoryKey]
                        : value?.url ?? DEFAULT_PLACEHOLDER_IMAGE[categoryKey]
                    }
                  >
                    {value.title && (
                      <Preview.Title color={colorText}>
                        {value.title}
                      </Preview.Title>
                    )}
                  </Preview>
                )}
              </>
            )}
          </>
        )}

        {!customButton && (
          <Button
            id={id}
            htmlType="button"
            onClick={openModal}
            loading={loading}
            disabled={disabled || (!isAssetViewAllowed && !isAssetEditAllowed)}
            className={IsTableView ? 'table-view-btn' : ''}
          >
            {value?.id ? 'Change' : 'Select'} {btnText}
          </Button>
        )}
      </Space>
    </>
  );
};

export default SelectAsset;
