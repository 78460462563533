import { gql } from '@apollo/client';

export const CREATE_VIDEO = gql`
  mutation createVideo($data: CreateVideoInput!) {
    createVideo(data: $data) {
      message
      video {
        id
      }
    }
  }
`;

export const UPDATE_VIDEO = gql`
  mutation updateVideo($data: UpdateVideoInput!, $id: ID!) {
    updateVideo(data: $data, where: { id: $id }) {
      message
    }
  }
`;
