import {
  DeleteOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  ColorPicker as AntdColorPicker,
  Select as AntdSelect,
  Button,
  Collapse,
  Flex,
  Form,
  Input,
  Modal,
  Radio,
  Tooltip,
  Upload
} from 'antd';
import { groupBy, isArray, map } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ASSET_CATEGORY,
  DATE_FORMATS,
  DATE_TIME_FORMATS,
  DEFAULT_COLOR,
  DEFAULT_WEB_COLORS,
  LOGO_POSITIONS,
  LOGO_SIZES,
  SHOW_MENU,
  TIME_FORMATS
} from '../../../common/constants';
import { fileUpload } from '../../../common/utils';
import TableComponent from '../../../components/CommonTable';
import useDebounce from '../../../hooks/useDebounce';
import SelectAsset from '../../assets/components/SelectAsset';
import Components from '../../component';
import Preview from '../../videos/components/Preview';

export function MobileView({ namePath, form }) {
  const colors = useDebounce(Form.useWatch(namePath, form));

  return <Components colors={colors} />;
}

function EmailVerification({ namePath }) {
  return (
    <Form.Item
      label="Email Verification Base URL"
      name={namePath}
      rules={[
        {
          type: 'url',
          message: 'Invalid URL'
        }
      ]}
    >
      <Input placeholder="Enter URL" />
    </Form.Item>
  );
}

function ColorPicker({ onChange, remove, ...rest }) {
  const handleDelete = (e) => {
    e?.stopPropagation();
    remove();
  };
  return (
    <AntdColorPicker
      format="hex"
      showText={(color) => (
        <span className="color-picker-text">
          {color?.toHexString()} <DeleteOutlined onClick={handleDelete} />
        </span>
      )}
      onChange={(_, hex) => onChange(hex)}
      {...rest}
    />
  );
}

function CommunityColors({ namePath }) {
  return (
    <Form.List name={namePath}>
      {(fields, { add, remove }) => (
        <div className="config-form-item">
          <span className="config-item-label">Community Colors</span>
          <div className="community-colors-wrapper">
            {fields?.map((field, index) => (
              <Form.Item className="m-0" key={field?.key} {...field}>
                <ColorPicker remove={() => remove(index)} />
              </Form.Item>
            ))}
            <Button
              onClick={() => {
                add(DEFAULT_COLOR);
              }}
              icon={<PlusOutlined />}
            >
              Add Color
            </Button>
          </div>
        </div>
      )}
    </Form.List>
  );
}

function AddImageModal({ open, onClose, onSubmit }) {
  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        form.submit();
      }}
      okText="Add"
      title="Add Community Image"
      afterOpenChange={(isOpen) => {
        if (!isOpen) {
          form?.resetFields();
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          url: ''
        }}
      >
        <Form.Item
          label="URL"
          name="url"
          rules={[
            {
              type: 'url',
              message: 'Invalid URL'
            },
            {
              required: true,
              message: 'Please provide image url'
            }
          ]}
        >
          <Input placeholder="Enter Image URL" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function ImageItem({ value, remove }) {
  return <Preview bgImg={value} onRemove={remove} />;
}

function CommunityImages({ namePath }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Form.List name={namePath}>
      {(fields, { add, remove }) => (
        <div className="config-form-item">
          <span className="config-item-label">Community Images</span>
          {fields?.length > 0 && (
            <div className="community-images-wrapper">
              {fields?.map((field, index) => (
                <Form.Item className="m-0" key={field?.key} {...field}>
                  <ImageItem
                    remove={() => {
                      remove(index);
                    }}
                  />
                </Form.Item>
              ))}
            </div>
          )}
          <Button onClick={handleOpen} icon={<PlusOutlined />}>
            Add Image
          </Button>
          <AddImageModal
            open={open}
            onClose={handleClose}
            onSubmit={({ url }) => {
              add(url);
              setOpen(false);
            }}
          />
        </div>
      )}
    </Form.List>
  );
}

const DEFAULT_IMAGE_SNIPPET = [
  {
    key: 'defaultDisplayImageKey',
    light: null,
    dark: null
  }
];

const uploadButton = (btnLoading) => (
  <div>
    {btnLoading ? <LoadingOutlined /> : <PlusOutlined />}
    <div className="mt-8">Upload</div>
  </div>
);

function DefaultImage({ form, btnLoading, handlePreview }) {
  const defaultImageArray = Form.useWatch(
    ['config', 'logos', 'defaultDisplayImageKey'],
    form
  );

  const columns = [
    {
      title: 'Light',
      dataIndex: 'light',
      key: 'light',
      render: () => {
        return (
          <Form.Item
            name={['config', 'logos', 'defaultDisplayImageKey', 'light']}
            rules={[{ required: true, message: 'Please upload default image' }]}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (isArray(e)) {
                return e;
              }
              return e?.fileList;
            }}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              beforeUpload={() => false}
              maxCount={1}
              accept=".png"
              onPreview={handlePreview}
            >
              {defaultImageArray?.light?.length > 0
                ? null
                : uploadButton(btnLoading)}
            </Upload>
          </Form.Item>
        );
      }
    },
    {
      title: 'Dark',
      dataIndex: 'dark',
      key: 'dark',
      render: () => {
        return (
          <Form.Item
            name={['config', 'logos', 'defaultDisplayImageKey', 'dark']}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (isArray(e)) {
                return e;
              }
              return e?.fileList;
            }}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              beforeUpload={() => false}
              maxCount={1}
              accept=".png"
              onPreview={handlePreview}
            >
              {defaultImageArray?.dark?.length > 0
                ? null
                : uploadButton(btnLoading)}
            </Upload>
          </Form.Item>
        );
      }
    }
  ];

  return (
    <>
      <div className="config-form-item">
        <span className="config-item-label">
          <div className="d-flex">
            <p className="mr-6">Default Image (Recommended: 1600 * 900)</p>
            <Tooltip title="Used as default image or placeholder image for website in all modules">
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </span>
        <TableComponent
          columns={columns}
          data={DEFAULT_IMAGE_SNIPPET}
          tableClassName="logos-table"
        />
      </div>
    </>
  );
}

const FONT_TYPES = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY'
};

const FONT_STYLES = {
  NORMAL: 'normal',
  ITALIC: 'italic',
  OBLIQUE: 'oblique'
};

const FONT_WEIGHTS = {
  NORMAL: 'normal',
  BOLD: 'bold',
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900'
};

const FONT_TYPES_NAMES = {
  [FONT_TYPES.PRIMARY]: 'Primary',
  [FONT_TYPES.SECONDARY]: 'Secondary',
  [FONT_TYPES.TERTIARY]: 'Tertiary'
};

const FONT_TYPE_OPTIONS = [
  { label: 'Primary', value: FONT_TYPES.PRIMARY },
  { label: 'Secondary', value: FONT_TYPES.SECONDARY },
  { label: 'Tertiary', value: FONT_TYPES.TERTIARY }
];

const FONT_STYLE_OPTIONS = [
  { label: 'Normal', value: FONT_STYLES.NORMAL },
  { label: 'Italic', value: FONT_STYLES.ITALIC },
  { label: 'Oblique', value: FONT_STYLES.OBLIQUE }
];

const FONT_WEIGHT_OPTIONS = [
  { label: 'Normal', value: FONT_WEIGHTS.NORMAL },
  { label: 'Bold', value: FONT_WEIGHTS.BOLD },
  { label: '100', value: FONT_WEIGHTS['100'] },
  { label: '200', value: FONT_WEIGHTS['200'] },
  { label: '300', value: FONT_WEIGHTS['300'] },
  { label: '400', value: FONT_WEIGHTS['400'] },
  { label: '500', value: FONT_WEIGHTS['500'] },
  { label: '600', value: FONT_WEIGHTS['600'] },
  { label: '700', value: FONT_WEIGHTS['700'] },
  { label: '800', value: FONT_WEIGHTS['800'] },
  { label: '900', value: FONT_WEIGHTS['900'] }
];

function AddFontModal({ open, onClose, onSubmit }) {
  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        form.submit();
      }}
      okText="Add"
      title="Add Font"
      afterOpenChange={(isOpen) => {
        if (!isOpen) {
          form?.resetFields();
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          type: FONT_TYPES.PRIMARY,
          font: null,
          weight: FONT_WEIGHTS.NORMAL,
          style: FONT_STYLES.NORMAL
        }}
      >
        <Form.Item required label="Type" name="type">
          <AntdSelect options={FONT_TYPE_OPTIONS} />
        </Form.Item>
        <Form.Item
          label="Font"
          name="font"
          required
          rules={[
            {
              required: true,
              message: 'Please select font file'
            }
          ]}
        >
          <SelectAsset
            modalTitle="Select Font File"
            btnText="File"
            categoryKey={ASSET_CATEGORY.DOCUMENT}
            dataSelector={({ id, title }) => ({
              id,
              title
            })}
          />
        </Form.Item>
        <Form.Item label="Weight" name="weight">
          <AntdSelect options={FONT_WEIGHT_OPTIONS} />
        </Form.Item>
        <Form.Item label="Style" name="style">
          <AntdSelect options={FONT_STYLE_OPTIONS} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function FontItem({ data, remove }) {
  return data?.map((font) => (
    <div key={font?.id} className="font-item">
      <span>{font?.font?.title}</span>
      <DeleteOutlined onClick={() => remove(font?.id)} />
    </div>
  ));
}

function Fonts({ namePath, form }) {
  const formFonts = Form.useWatch(namePath, form);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRemove = useCallback(
    (id) => {
      const oldValue = form?.getFieldValue(namePath) || [];
      const newValue = oldValue?.filter((font) => font?.id !== id);

      form?.setFieldValue(namePath, newValue);
    },
    [form]
  );

  const handleRemoveAll = useCallback(
    (type) => {
      const oldValue = form?.getFieldValue(namePath) || [];
      const newValue = oldValue?.filter((font) => font?.type !== type);

      form?.setFieldValue(namePath, newValue);
    },
    [form]
  );

  const fonts = useMemo(
    () =>
      Object.entries(groupBy(formFonts, 'type')).map(([key, value]) => ({
        key,
        label: FONT_TYPES_NAMES[key],
        children: <FontItem data={value} remove={handleRemove} />,
        extra: <DeleteOutlined onClick={() => handleRemoveAll(key)} />
      })),
    [formFonts, handleRemove, handleRemoveAll]
  );

  return (
    <Form.Item
      className="m-0"
      name={namePath}
      label="Fonts"
      rules={[{ required: true, message: 'Please select font' }]}
    >
      <div className="config-form-item">
        {fonts?.length > 0 && (
          <Collapse
            defaultActiveKey={Object.values(FONT_TYPES)}
            bordered={false}
            className="mb-12"
            expandIconPosition="start"
            items={fonts}
          />
        )}
        <Button onClick={handleOpen} icon={<PlusOutlined />}>
          Add Font
        </Button>
        <AddFontModal
          open={open}
          onClose={handleClose}
          onSubmit={(value) => {
            const oldValue = form?.getFieldValue(namePath) || [];
            const newValue = [...oldValue, { ...value, id: uuidv4() }];
            form?.setFieldValue(namePath, newValue);
            setOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );
}

const WEB_COLORS_SNIPPET = [
  {
    key: 'colorPrimary',
    title: 'Primary Color',
    light: DEFAULT_WEB_COLORS?.light?.colorPrimary,
    dark: DEFAULT_WEB_COLORS?.dark?.colorPrimary
  },
  {
    key: 'colorBgBase',
    title: 'Background Color',
    light: DEFAULT_WEB_COLORS?.light?.colorBgBase,
    dark: DEFAULT_WEB_COLORS?.dark?.colorBgBase
  },
  {
    key: 'colorTextBase',
    title: 'Text Color',
    light: DEFAULT_WEB_COLORS?.light?.colorTextBase,
    dark: DEFAULT_WEB_COLORS?.dark?.colorTextBase
  },
  {
    key: 'colorBgLayout',
    title: 'Layout Color',
    light: DEFAULT_WEB_COLORS?.light?.colorBgLayout,
    dark: DEFAULT_WEB_COLORS?.dark?.colorBgLayout
  },
  {
    key: 'colorForeground',
    title: 'Foreground Color',
    light: DEFAULT_WEB_COLORS?.light?.colorForeground,
    dark: DEFAULT_WEB_COLORS?.dark?.colorForeground
  },
  {
    key: 'colorBorder',
    title: 'Border Color',
    light: DEFAULT_WEB_COLORS?.light?.colorBorder,
    dark: DEFAULT_WEB_COLORS?.dark?.colorBorder
  }
];

const LOGOS_SNIPPET = [
  {
    key: 'logos',
    light: null,
    dark: null
  }
];

function ColorSnippetPicker({ onChange = () => {}, ...rest }) {
  return (
    <AntdColorPicker
      format="hex"
      onChange={(_, hex) => {
        onChange(hex);
      }}
      defaultValue="#000000"
      {...rest}
    />
  );
}

function Colors({ namePath, form }) {
  const columns = [
    {
      title: 'Colors',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Light',
      dataIndex: 'light',
      key: 'light',
      render: (value, record) => {
        return (
          <Form.Item name={[...namePath, 'light', record?.key]}>
            <ColorSnippetPicker
              showText={() => (
                <span
                  onClick={(e) => {
                    e?.stopPropagation();
                    const colorsPaths = record?.key && [
                      ...namePath,
                      'light',
                      record.key
                    ];
                    if (colorsPaths?.length > 0) {
                      form?.setFieldValue(
                        [...colorsPaths],
                        DEFAULT_WEB_COLORS?.light?.[record?.key]
                      );
                    }
                  }}
                >
                  Reset
                </span>
              )}
            />
          </Form.Item>
        );
      }
    },
    {
      title: 'Dark',
      dataIndex: 'dark',
      key: 'dark',
      render: (value, record) => {
        return (
          <Form.Item name={[...namePath, 'dark', record?.key]}>
            <ColorSnippetPicker
              showText={() => (
                <span
                  onClick={(e) => {
                    e?.stopPropagation();
                    const colorsPaths = record?.key && [
                      ...namePath,
                      'dark',
                      record.key
                    ];
                    if (colorsPaths?.length > 0) {
                      form?.setFieldValue(
                        [...colorsPaths],
                        DEFAULT_WEB_COLORS?.dark?.[record?.key]
                      );
                    }
                  }}
                >
                  Reset
                </span>
              )}
            />
          </Form.Item>
        );
      }
    }
  ];
  return (
    <div className="config-form-item">
      <span className="config-item-label">Colors</span>
      <TableComponent columns={columns} data={WEB_COLORS_SNIPPET} />
    </div>
  );
}

export function Logos({ namePath, form, btnLoading, handlePreview }) {
  const imageKeyArray = Form.useWatch([...namePath, 'imageKey'], form);
  const faviconKeyArray = Form.useWatch([...namePath, 'faviconKey'], form);
  const columns = [
    {
      title: 'Light',
      dataIndex: 'light',
      key: 'light',
      render: () => {
        return (
          <Flex gap={24}>
            <Form.Item
              name={[...namePath, 'imageKey', 'light']}
              label="Logo"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              rules={[{ required: true, message: 'Please select logo!' }]}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                beforeUpload={() => false}
                maxCount={1}
                accept=".jpg,.jpeg,.png"
                onPreview={handlePreview}
              >
                {imageKeyArray?.light?.length > 0
                  ? null
                  : uploadButton(btnLoading)}
              </Upload>
            </Form.Item>
            <Form.Item
              name={[...namePath, 'faviconKey', 'light']}
              label="Favicon"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              rules={[{ required: true, message: 'Please select favicon!' }]}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                beforeUpload={() => false}
                maxCount={1}
                accept=".ico,.png"
                onPreview={handlePreview}
              >
                {faviconKeyArray?.light?.length > 0
                  ? null
                  : uploadButton(btnLoading)}
              </Upload>
            </Form.Item>
          </Flex>
        );
      }
    },
    {
      title: 'Dark',
      dataIndex: 'dark',
      key: 'dark',
      render: () => {
        return (
          <Flex gap={24}>
            <Form.Item
              name={[...namePath, 'imageKey', 'dark']}
              label="Logo"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                beforeUpload={() => false}
                maxCount={1}
                accept=".jpg,.jpeg,.png"
                onPreview={handlePreview}
              >
                {imageKeyArray?.dark?.length > 0
                  ? null
                  : uploadButton(btnLoading)}
              </Upload>
            </Form.Item>
            <Form.Item
              name={[...namePath, 'faviconKey', 'dark']}
              label="Favicon"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                beforeUpload={() => false}
                maxCount={1}
                accept=".ico,.png"
                onPreview={handlePreview}
              >
                {faviconKeyArray?.dark?.length > 0
                  ? null
                  : uploadButton(btnLoading)}
              </Upload>
            </Form.Item>
          </Flex>
        );
      }
    }
  ];
  return (
    <div className="config-form-item">
      <TableComponent
        columns={columns}
        data={LOGOS_SNIPPET}
        tableClassName="logos-table"
      />
    </div>
  );
}

export const DateTimeComponent = ({
  namePath,
  form,
  setIsCustomSelected,
  isCustomSelected
}) => {
  const currentDate = moment();

  const customDate = Form.useWatch([...namePath, 'date']);
  const customTime = Form.useWatch([...namePath, 'time']);
  const customDateFormat = Form.useWatch([...namePath, 'customDateFormat']);
  const customTimeFormat = Form.useWatch([...namePath, 'customTimeFormat']);

  const dateFormats = [
    {
      key: '1',
      type: DATE_TIME_FORMATS?.DATE_FORMAT,
      label: currentDate?.format(DATE_FORMATS?.DATE_FORMAT_LONG),
      value: DATE_FORMATS?.DATE_FORMAT_LONG
    },
    {
      key: '2',
      type: DATE_TIME_FORMATS?.DATE_FORMAT,
      label: currentDate?.format(DATE_FORMATS?.STANDARD_DATE_FORMAT),
      value: DATE_FORMATS?.STANDARD_DATE_FORMAT
    },
    {
      key: '3',
      type: DATE_TIME_FORMATS?.DATE_FORMAT,
      label: currentDate?.format(DATE_FORMATS?.DATE_FORMAT_US),
      value: DATE_FORMATS?.DATE_FORMAT_US
    },
    {
      key: '4',
      type: DATE_TIME_FORMATS?.DATE_FORMAT,
      label: currentDate?.format(DATE_FORMATS?.DATE_FORMAT_EU),
      value: DATE_FORMATS?.DATE_FORMAT_EU
    },
    {
      key: '5',
      type: DATE_TIME_FORMATS?.DATE_FORMAT,
      label: 'Custom',
      value:
        isCustomSelected?.date &&
        form?.getFieldValue([...namePath, 'customDateFormat'])
          ? form?.getFieldValue([...namePath, 'date']) || ''
          : '',
      isCustom: true
    }
  ];

  const timeFormats = [
    {
      key: '6',
      type: DATE_TIME_FORMATS?.TIME_FORMAT,
      label: currentDate?.format(TIME_FORMATS?.TIME_FORMAT_12H_LOWER),
      value: TIME_FORMATS?.TIME_FORMAT_12H_LOWER
    },
    {
      key: '7',
      type: DATE_TIME_FORMATS?.TIME_FORMAT,
      label: currentDate?.format(TIME_FORMATS?.TIME_FORMAT_12H_UPPER),
      value: TIME_FORMATS?.TIME_FORMAT_12H_UPPER
    },
    {
      key: '8',
      type: DATE_TIME_FORMATS?.TIME_FORMAT,
      label: currentDate?.format(TIME_FORMATS?.TIME_FORMAT_24H),
      value: TIME_FORMATS?.TIME_FORMAT_24H
    },
    {
      key: '9',
      type: DATE_TIME_FORMATS?.TIME_FORMAT,
      label: 'Custom',
      value:
        isCustomSelected?.time &&
        form?.getFieldValue([...namePath, 'customTimeFormat'])
          ? form?.getFieldValue([...namePath, 'time']) || ''
          : '',
      isCustom: true
    }
  ];

  const data = [
    { key: '1', format: DATE_TIME_FORMATS?.DATE_FORMAT },
    { key: '2', format: DATE_TIME_FORMATS?.TIME_FORMAT }
  ];

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => {
        if (record?.format === DATE_TIME_FORMATS?.DATE_FORMAT) {
          return (
            <div className="d-flex flex-vertical">
              <Form.Item name={[...namePath, 'date']}>
                <Radio.Group>
                  <div className="d-flex flex-vertical">
                    {dateFormats?.map((option) => (
                      <Radio
                        value={option?.value}
                        key={option?.key}
                        className="m-8"
                        onChange={(e) => {
                          const {
                            target: { value }
                          } = e;
                          if (!Object.values(DATE_FORMATS)?.includes(value)) {
                            setIsCustomSelected({
                              ...isCustomSelected,
                              date: true
                            });
                          } else {
                            setIsCustomSelected({
                              ...isCustomSelected,
                              date: false
                            });
                          }
                        }}
                      >
                        {option?.label}
                      </Radio>
                    ))}
                  </div>
                </Radio.Group>
              </Form.Item>
              <p>
                Preview:
                {isCustomSelected?.date
                  ? currentDate?.format(customDateFormat)
                  : currentDate?.format(
                      form?.getFieldValue([...namePath, 'date'])
                    )}
              </p>
            </div>
          );
        }
        if (record?.format === DATE_TIME_FORMATS?.TIME_FORMAT) {
          return (
            <div className="d-flex flex-vertical">
              <Form.Item name={[...namePath, 'time']}>
                <Radio.Group>
                  <div className="d-flex flex-vertical">
                    {timeFormats?.map((option) => (
                      <Radio
                        value={option?.value}
                        key={option?.key}
                        className="m-8"
                        onChange={(e) => {
                          const {
                            target: { value }
                          } = e;
                          if (!Object.values(TIME_FORMATS)?.includes(value)) {
                            setIsCustomSelected({
                              ...isCustomSelected,
                              time: true
                            });
                          } else {
                            setIsCustomSelected({
                              ...isCustomSelected,
                              time: false
                            });
                          }
                        }}
                      >
                        {option?.label}
                      </Radio>
                    ))}
                  </div>
                </Radio.Group>
              </Form.Item>
              <p>
                Preview:
                {isCustomSelected?.time
                  ? currentDate?.format(customTimeFormat)
                  : currentDate?.format(
                      form?.getFieldValue([...namePath, 'time'])
                    )}
              </p>
            </div>
          );
        }

        return null;
      }
    },
    {
      title: 'Format',
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => {
        if (record.format === DATE_TIME_FORMATS?.DATE_FORMAT) {
          return (
            <div className="d-flex flex-vertical">
              {dateFormats?.map((option) => (
                <>
                  {option?.isCustom ? (
                    <Form.Item
                      name={[...namePath, 'customDateFormat']}
                      rules={[
                        {
                          required:
                            customDate === '' || customDateFormat === '',
                          message: 'Please enter custom date format'
                        }
                      ]}
                    >
                      <Input
                        placeholder="Enter custom date format"
                        className="date-time-input"
                        disabled={!isCustomSelected?.date}
                      />
                    </Form.Item>
                  ) : (
                    <div key={option?.key} className="m-8 date-time-value">
                      {option?.value}
                    </div>
                  )}
                </>
              ))}
            </div>
          );
        }
        if (record.format === DATE_TIME_FORMATS?.TIME_FORMAT) {
          return (
            <div className="d-flex flex-vertical">
              {timeFormats?.map((option) => (
                <>
                  {option?.isCustom ? (
                    <Form.Item
                      name={[...namePath, 'customTimeFormat']}
                      rules={[
                        {
                          required:
                            customTime === '' || customTimeFormat === '',
                          message: 'Please enter custom time format'
                        }
                      ]}
                    >
                      <Input
                        placeholder="Enter custom time format"
                        className="date-time-input"
                        disabled={!isCustomSelected?.time}
                      />
                    </Form.Item>
                  ) : (
                    <div key={option?.key} className="m-8 date-time-value">
                      {option?.value}
                    </div>
                  )}
                </>
              ))}
            </div>
          );
        }
        return null;
      }
    }
  ];

  return (
    <div className="config-form-item">
      <span className="config-item-label">
        <div className="d-flex">
          <p className="mr-6">Date & Time Formats</p>
        </div>
      </span>
      <TableComponent
        columns={columns}
        data={data}
        tableClassName="date-time-table mt-16"
      />
      <div className="custom-date-time-note">
        <p className="custom-note">
          Note:
          <a
            href="https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/"
            target="_blank"
            rel="noreferrer"
          >
            Documentation on date and time formatting.
          </a>
        </p>
      </div>
    </div>
  );
};

export const DEFAULT_CONFIG_KEYS = {
  EMAIL_VERIFICATION_BASE_URL: 'EMAIL_VERIFICATION_BASE_URL',
  COMMUNITY_IMAGE_URLS: 'COMMUNITY_IMAGE_URLS',
  COMMUNITY_COLORS: 'COMMUNITY_COLORS',
  FONTS: 'FONTS',
  COLORS: 'COLORS',
  LOGO_SIZE: 'LOGO_SIZE',
  LOGO_POSITION: 'LOGO_POSITION',
  DEFAULT_IMAGE: 'DEFAULT_DISPLAY_IMAGE',
  SHOW_MENU: 'SHOW_MENU',
  LOGOS: 'LOGOS',
  DATE_TIME_FORMATS: 'DATE_TIME_FORMATS'
};

export const DEFAULT_CONFIGS = {
  [DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE]: {
    component: DefaultImage
  },
  [DEFAULT_CONFIG_KEYS.DATE_TIME_FORMATS]: {
    name: 'dateTime',
    component: DateTimeComponent
  },
  [DEFAULT_CONFIG_KEYS.EMAIL_VERIFICATION_BASE_URL]: {
    component: EmailVerification,
    name: 'emailVerificationBaseURL'
  },
  [DEFAULT_CONFIG_KEYS.COMMUNITY_IMAGE_URLS]: {
    component: CommunityImages,
    name: 'communityImages'
  },
  [DEFAULT_CONFIG_KEYS.COMMUNITY_COLORS]: {
    component: CommunityColors,
    name: 'communityColors'
  },
  [DEFAULT_CONFIG_KEYS.FONTS]: {
    component: Fonts,
    name: 'fonts'
  },
  [DEFAULT_CONFIG_KEYS.COLORS]: {
    component: Colors,
    name: 'colors'
  },
  [DEFAULT_CONFIG_KEYS.LOGO_POSITION]: {
    name: 'logoPosition'
  },
  [DEFAULT_CONFIG_KEYS.LOGO_SIZE]: {
    name: 'logoSize'
  },
  [DEFAULT_CONFIG_KEYS.SHOW_MENU]: {
    name: 'showMenu'
  },
  [DEFAULT_CONFIG_KEYS.LOGOS]: {
    name: 'logos'
  }
};

const uploadLogos = async (
  logos,
  signedUrl,
  uploadProgress,
  workspaceId,
  isApp
) => {
  const files = [
    logos?.imageKey?.light?.[0],
    logos?.faviconKey?.light?.[0],
    logos?.imageKey?.dark?.[0],
    logos?.faviconKey?.dark?.[0],
    logos?.defaultDisplayImageKey?.light?.[0],
    logos?.defaultDisplayImageKey?.dark?.[0]
  ];

  const signedUrls = await Promise.all(
    map(files, (file) => {
      if (file) {
        if (file?.url) {
          return file;
        }
        return signedUrl({
          variables: {
            data: {
              contentType: file?.type,
              fileName: file?.name,
              uuid: uuidv4(),
              ...(isApp && { workspaceId })
            }
          }
        });
      }
    })
  );

  await Promise.all(
    map(signedUrls, (item, index) => {
      if (item && !item?.url) {
        if (isApp) {
          const {
            data: {
              getWorkspaceAppImageUploadSignedUrl: { signedUrl: fileSignedUrl }
            } = {}
          } = item;
          const file = files?.[index]?.originFileObj;
          return fileUpload(fileSignedUrl, file, (val) =>
            uploadProgress((prev) => ({
              ...prev,
              [index]: val
            }))
          );
        }
        const {
          data: {
            getWorkspaceImageUploadSignedUrl: { signedUrl: fileSignedUrl }
          } = {}
        } = item;
        const file = files?.[index]?.originFileObj;
        return fileUpload(fileSignedUrl, file, (val) =>
          uploadProgress((prev) => ({
            ...prev,
            [index]: val
          }))
        );
      }
    })
  );

  const [
    imageLight = '',
    faviconLight = '',
    imageDark = '',
    faviconDark = '',
    defaultImageLight = '',
    defaultImageDark = ''
  ] = await Promise.all(
    map(signedUrls, (item) => {
      if (item) {
        if (item?.url) return item?.key;
        if (isApp) {
          const {
            data: { getWorkspaceAppImageUploadSignedUrl: { key } } = {}
          } = item;
          return key;
        }
        const {
          data: { getWorkspaceImageUploadSignedUrl: { key } } = {}
        } = item;
        return key;
      }
    })
  );

  return {
    imageKey: {
      light: imageLight,
      dark: imageDark
    },
    faviconKey: {
      light: faviconLight,
      dark: faviconDark
    },
    defaultDisplayImageKey: {
      light: defaultImageLight,
      dark: defaultImageDark
    }
  };
};

export const getConfigData = async ({
  config,
  combinedValues,
  signedUrl,
  uploadProgress,
  allowedKeys = Object.values(DEFAULT_CONFIG_KEYS),
  workspaceId,
  isApp
}) => {
  if (!config) return [];

  const newConfig = [
    {
      key: DEFAULT_CONFIG_KEYS.EMAIL_VERIFICATION_BASE_URL,
      value: config?.emailVerificationBaseURL || '',
      type: 'PROTECTED',
      dataType: 'STRING'
    },
    {
      key: DEFAULT_CONFIG_KEYS.COMMUNITY_COLORS,
      value: config?.communityColors?.join(',') || '',
      type: 'PUBLIC',
      dataType: 'ARRAY'
    },
    {
      key: DEFAULT_CONFIG_KEYS.COMMUNITY_IMAGE_URLS,
      value: config?.communityImages?.join(',') || '',
      type: 'PUBLIC',
      dataType: 'ARRAY'
    },
    {
      key: DEFAULT_CONFIG_KEYS.COLORS,
      value: combinedValues || {},
      type: 'PUBLIC',
      dataType: 'JSON'
    },
    {
      key: DEFAULT_CONFIG_KEYS.FONTS,
      value:
        config?.fonts?.map((font) => ({
          type: font?.type,
          fontId: font?.font?.id,
          weight: font?.weight,
          style: font?.style
        })) || [],
      type: 'PUBLIC',
      dataType: 'JSON'
    },
    {
      key: DEFAULT_CONFIG_KEYS.LOGO_SIZE,
      value: config?.logoSize || LOGO_SIZES.SMALL,
      type: 'PUBLIC',
      dataType: 'STRING'
    },
    {
      key: DEFAULT_CONFIG_KEYS.LOGO_POSITION,
      value: config?.logoPosition || LOGO_POSITIONS.CENTER,
      type: 'PUBLIC',
      dataType: 'STRING'
    },
    {
      key: DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE,
      value: config?.defaultImage || '',
      type: 'PUBLIC',
      dataType: 'STRING'
    },
    {
      key: DEFAULT_CONFIG_KEYS.SHOW_MENU,
      value: config?.showMenu || SHOW_MENU.NO,
      type: 'PUBLIC',
      dataType: 'STRING'
    },
    {
      key: DEFAULT_CONFIG_KEYS.LOGOS,
      type: 'PUBLIC',
      dataType: 'JSON',
      value: await uploadLogos(
        config?.logos,
        signedUrl,
        uploadProgress,
        workspaceId,
        isApp
      )
    },
    {
      key: DEFAULT_CONFIG_KEYS.DATE_TIME_FORMATS,
      value: {
        date: [config?.dateTime?.date || config?.dateTime?.customDateFormat],
        time: [config.dateTime?.time || config?.dateTime?.customTimeFormat]
      },
      dataType: 'JSON',
      type: 'PUBLIC'
    }
  ];

  return newConfig?.filter(({ key }) => allowedKeys?.includes(key));
};

export const parseConfigData = (
  configs,
  form,
  setIsCustomSelected,
  isCustomSelected
) => {
  if (!configs?.length) return null;

  const config = {};

  configs?.forEach((item) => {
    const defaultConfig = DEFAULT_CONFIGS?.[item?.key];
    if (defaultConfig) {
      switch (item?.key) {
        case DEFAULT_CONFIG_KEYS.EMAIL_VERIFICATION_BASE_URL: {
          config[defaultConfig?.name] = item?.value || '';
          break;
        }
        case DEFAULT_CONFIG_KEYS.COMMUNITY_COLORS:
          config[defaultConfig?.name] = item?.value
            ? item?.value?.split(',')
            : [];
          break;
        case DEFAULT_CONFIG_KEYS.COMMUNITY_IMAGE_URLS:
          config[defaultConfig?.name] = item?.value
            ? item?.value?.split(',')
            : [];
          break;
        case DEFAULT_CONFIG_KEYS.COLORS:
          config[defaultConfig?.name] = item?.value
            ? {
                light: {
                  colorPrimary:
                    item?.value?.light?.primaryBackground ||
                    DEFAULT_WEB_COLORS.light.colorPrimary,
                  colorBgBase:
                    item?.value?.light?.body1 ||
                    DEFAULT_WEB_COLORS.light.colorBgBase,
                  colorTextBase:
                    item?.value?.light?.contentPrimary ||
                    DEFAULT_WEB_COLORS.light.colorTextBase,
                  colorBgLayout:
                    item?.value?.light?.body2 ||
                    DEFAULT_WEB_COLORS.light.colorBgLayout,
                  colorForeground:
                    item?.value?.light?.primaryForeground ||
                    DEFAULT_WEB_COLORS.light.colorForeground,
                  colorBorder:
                    item?.value?.light?.border ||
                    DEFAULT_WEB_COLORS.light.colorBorder
                },
                dark: {
                  colorPrimary:
                    item?.value?.dark?.primaryBackground ||
                    DEFAULT_WEB_COLORS.dark.colorPrimary,
                  colorBgBase:
                    item?.value?.dark?.body1 ||
                    DEFAULT_WEB_COLORS.dark.colorBgBase,
                  colorTextBase:
                    item?.value?.dark?.contentPrimary ||
                    DEFAULT_WEB_COLORS.dark.colorTextBase,
                  colorBgLayout:
                    item?.value?.dark?.body2 ||
                    DEFAULT_WEB_COLORS.dark.colorBgLayout,
                  colorForeground:
                    item?.value?.dark?.primaryForeground ||
                    DEFAULT_WEB_COLORS.dark.colorForeground,
                  colorBorder:
                    item?.value?.dark?.border ||
                    DEFAULT_WEB_COLORS.dark.colorBorder
                }
              }
            : DEFAULT_WEB_COLORS;
          break;
        case DEFAULT_CONFIG_KEYS.FONTS: {
          config[defaultConfig?.name] =
            item?.fonts?.map((font) => ({
              ...font,
              id: uuidv4()
            })) || [];
          break;
        }
        case DEFAULT_CONFIG_KEYS.LOGO_SIZE:
          config[defaultConfig?.name] = item?.value || LOGO_SIZES.MEDIUM;
          break;
        case DEFAULT_CONFIG_KEYS.LOGO_POSITION:
          config[defaultConfig?.name] = item?.value || LOGO_POSITIONS.CENTER;
          break;
        case DEFAULT_CONFIG_KEYS.DEFAULT_IMAGE:
          config[defaultConfig?.name] = item?.value
            ? [{ url: item?.value }]
            : [];
          break;
        case DEFAULT_CONFIG_KEYS.SHOW_MENU:
          config[defaultConfig?.name] = item?.value || SHOW_MENU.NO;
          break;
        case DEFAULT_CONFIG_KEYS.LOGOS:
          config[defaultConfig?.name] =
            {
              imageKey: {
                light: item?.logos?.image?.light
                  ? [
                      {
                        url: item?.logos?.image?.light,
                        key: item?.value?.imageKey?.light
                      }
                    ]
                  : [],
                dark: item?.logos?.image?.dark
                  ? [
                      {
                        url: item?.logos?.image?.dark,
                        key: item?.value?.imageKey?.dark
                      }
                    ]
                  : []
              },
              faviconKey: {
                light: item?.logos?.favicon?.light
                  ? [
                      {
                        url: item?.logos?.favicon?.light,
                        key: item?.value?.faviconKey?.light
                      }
                    ]
                  : [],
                dark: item?.logos?.favicon?.dark
                  ? [
                      {
                        url: item?.logos?.favicon?.dark,
                        key: item?.value?.faviconKey?.dark
                      }
                    ]
                  : []
              },
              defaultDisplayImageKey: {
                light: item?.logos?.defaultDisplayImage?.light
                  ? [
                      {
                        url: item?.logos?.defaultDisplayImage?.light,
                        key: item?.value?.defaultDisplayImageKey?.light
                      }
                    ]
                  : [],
                dark: item?.logos?.defaultDisplayImage?.dark
                  ? [
                      {
                        url: item?.logos?.defaultDisplayImage?.dark,
                        key: item?.value?.defaultDisplayImageKey?.dark
                      }
                    ]
                  : []
              }
            } ?? null;
          break;
        case DEFAULT_CONFIG_KEYS.DATE_TIME_FORMATS: {
          if (setIsCustomSelected) {
            if (
              !Object.values(TIME_FORMATS)?.includes(item?.value?.time?.[0]) &&
              !Object.values(DATE_FORMATS)?.includes(item?.value?.date?.[0])
            ) {
              form?.setFieldsValue({
                config: {
                  dateTime: {
                    customDateFormat: item?.value?.date?.[0],
                    customTimeFormat: item?.value?.time?.[0]
                  }
                }
              });
              setIsCustomSelected({
                time: true,
                date: true
              });
            } else if (
              !Object.values(DATE_FORMATS)?.includes(item?.value?.date?.[0])
            ) {
              form?.setFieldsValue({
                config: {
                  dateTime: {
                    customDateFormat: item?.value?.date?.[0]
                  }
                }
              });
              setIsCustomSelected({
                ...isCustomSelected,
                date: true
              });
            } else if (
              !Object.values(TIME_FORMATS)?.includes(item?.value?.time?.[0])
            ) {
              form?.setFieldsValue({
                config: {
                  dateTime: {
                    customTimeFormat: item?.value?.time?.[0]
                  }
                }
              });
              setIsCustomSelected({
                ...isCustomSelected,
                time: true
              });
            }
          }

          config[defaultConfig?.name] = item?.value
            ? {
                date: item?.value?.date?.[0],
                time: item?.value?.time?.[0]
              }
            : {};
          break;
        }
        default:
          break;
      }
    }
  });

  return config;
};
