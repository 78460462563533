import { gql } from '@apollo/client';

export const GET_CONTRIBUTORS = gql`
  query contributorsAdmin(
    $filter: ContributorsFilter
    $sort: ContributorsSort
    $where: ContributorsWhereInput
  ) {
    contributorsAdmin(filter: $filter, sort: $sort, where: $where) {
      count
      contributors {
        id
        firstName
        lastName
        displayName
        description
        subTitle
        slug
        createdAt
        image {
          id
          url
          blurhash
        }
        thumbnail {
          id
          url
          blurhash
        }
        primaryColor
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CONTRIBUTOR = gql`
  query contributorAdmin($where: ContributorWhereUniqueInput!) {
    contributorAdmin(where: $where) {
      id
      firstName
      lastName
      displayName
      description
      subTitle
      slug
      createdAt
      seo
      metaHeader
      metaFooter
      isActive
      image {
        id
        url
      }
      thumbnail {
        id
        url
      }
      primaryColor
      createdAt
      updatedAt
    }
  }
`;

export const GET_ASSETS = gql`
  query assets($filter: AssetsFilter, $sort: AssetsSort) {
    assets(filter: $filter, sort: $sort) {
      count
      assets {
        id
        title
        description
        categoryKey
        serviceVideoThumbnail
        url
        createdAt
      }
    }
  }
`;
