import { useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import { isFunction } from 'lodash';
import React, { useEffect } from 'react';
import { MAX_LENGTHS } from '../../../../common/constants';
import { formValidatorRules } from '../../../../common/utils';
import { ADD_PAGE_GROUP, UPDATE_PAGE_GROUP } from '../../graphql/Mutations';

const initialValues = {
  name: ''
};

const AddEditGroupModal = ({ open, onClose, data, onSuccess }) => {
  const isEdit = Boolean(data);
  const [form] = Form.useForm();

  useEffect(() => {
    if (open && data) {
      form.setFieldsValue({
        name: data?.name ?? ''
      });
    }
  }, [open, data, form]);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const [addEditGroup, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_GROUP : ADD_PAGE_GROUP,
    {
      onError() {},
      onCompleted() {
        if (isFunction(onSuccess)) onSuccess();
        handleClose();
      }
    }
  );

  const handleSubmit = (values) => {
    addEditGroup({
      variables: {
        data: {
          name: values?.name ?? ''
        },
        ...(isEdit && {
          where: {
            id: data?.id
          }
        })
      }
    });
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      title={isEdit ? 'Update page group' : 'Create page group'}
      okText={isEdit ? 'Save' : 'Create'}
      okButtonProps={{
        form: 'add-edit-group-form',
        htmlType: 'submit'
      }}
      confirmLoading={loading}
    >
      <Form
        id="add-edit-group-form"
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          label="name"
          name="name"
          rules={[
            formValidatorRules?.required('Please enter name!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditGroupModal;
