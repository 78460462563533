import { gql } from '@apollo/client';
import { PAGE_MODULE_DETAILS_FRAGMENT } from './Queries';

export const CREATE_PAGE = gql`
  mutation createPage($data: CreatePageInput!) {
    createPage(data: $data) {
      message
      page {
        id
        slug
        title
        description
        status
        type
        isDefault
        pageGroup {
          name
          id
        }
        createdBy {
          id
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_PAGE = gql`
  mutation deletePage($id: ID!) {
    deletePage(where: { id: $id }) {
      message
    }
  }
`;

export const UPDATE_PAGE = gql`
  mutation updatePage($where: PageUniqueInput!, $data: UpdatePageInput!) {
    updatePage(where: $where, data: $data) {
      message
      page {
        slug
        title
        description
        seo
        metaHeader
        metaFooter
        permissions
        allowDelete
        apps {
          id
          name
        }
        pageGroup {
          name
          id
        }
      }
    }
  }
`;

export const UPDATE_PAGE_STATUS = gql`
  mutation updatePageStatus($id: ID!, $status: PageStatus!) {
    updatePageStatus(where: { id: $id }, data: { status: $status }) {
      message
      page {
        id
      }
    }
  }
`;

export const CREATE_PAGE_MODULE = gql`
  ${PAGE_MODULE_DETAILS_FRAGMENT}
  mutation createPageModule($id: ID!, $data: CreatePageModuleInput!) {
    addUpdatedPageModule: createPageModule(
      where: { pageId: $id }
      data: $data
    ) {
      message
      pageModule {
        ...pageModuleDetails
      }
    }
  }
`;

export const UPDATE_PAGE_MODULE = gql`
  ${PAGE_MODULE_DETAILS_FRAGMENT}
  mutation updatePageModule($id: ID!, $data: UpdatePageModuleInput!) {
    addUpdatedPageModule: updatePageModule(where: { id: $id }, data: $data) {
      message
      pageModule {
        ...pageModuleDetails
      }
    }
  }
`;

export const UPDATE_PAGE_MODULE_ORDER = gql`
  mutation updatePageModuleOrder($id: ID!, $order: Int!) {
    updatePageModuleOrder(where: { id: $id }, data: { order: $order }) {
      message
    }
  }
`;

export const DELETE_PAGE_MODULE = gql`
  mutation deletePageModule($id: ID!) {
    deletePageModule(where: { id: $id }) {
      message
    }
  }
`;

export const FORCE_PURGE_PAGE_CACHE = gql`
  mutation purgePageCache(
    $type: PageType
    $slug: String
    $all: Boolean = false
  ) {
    purgePageCache(where: { type: $type, slug: $slug, all: $all }) {
      message
    }
  }
`;

export const ADD_PAGE_GROUP = gql`
  mutation CreatePageGroup($data: CreatePageGroupInput!) {
    createPageGroup(data: $data) {
      message
    }
  }
`;

export const UPDATE_PAGE_GROUP = gql`
  mutation UpdatePageGroup(
    $where: PageGroupInput!
    $data: UpdatePageGroupInput!
  ) {
    updatePageGroup(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_PAGE_GROUP = gql`
  mutation DeletePageGroup($where: PageGroupInput!) {
    deletePageGroup(where: $where) {
      message
    }
  }
`;

export const REVALIDATE_CONFIG_FILE = gql`
  mutation RevalidateConfigFile($data: RevalidateConfigFileInput!) {
    revalidateConfigFile(data: $data) {
      message
    }
  }
`;
