import React, { forwardRef } from 'react';
import { Select } from '../../../videos/components/FormInputs';
import { PAGE_GROUPS } from '../../graphql/Queries';

const variablesSelector = ({ skip, limit, search }) => ({
  filter: { skip, limit, search }
});

const SelectGroup = forwardRef(({ ...rest }, ref) => {
  return (
    <Select
      ref={ref}
      allowClear
      className="group-select"
      popupMatchSelectWidth={false}
      placeholder="Select page group"
      query={PAGE_GROUPS}
      dataSelector={(response) =>
        response?.pageGroups?.pageGroups?.map(({ name, id }) => ({
          label: name,
          value: id
        })) || []
      }
      keys={{
        data: 'pageGroups',
        records: 'pageGroups',
        count: 'count'
      }}
      variablesSelector={variablesSelector}
      {...rest}
    />
  );
});

SelectGroup.displayName = 'SelectGroup';

export default SelectGroup;
