import { Button, Modal, Space, Typography } from 'antd';
import { isFunction } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import history from '../historyData';

const { Title } = Typography;

export default function RouterPrompt(props) {
  const { dispatch } = useContext(AppContext);
  const {
    openPrompt = false,
    title = 'Leave this page',
    description = 'Changes made will not be saved, are you sure?',
    okText = 'Confirm',
    cancelText = 'Cancel',
    handleContinue,
    pageFilters = {},
    onCancel
  } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const currentLocation = useLocation();

  const unblockRef = useRef();

  useEffect(() => {
    if (openPrompt) {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = function () {
        return true;
      };
    }
    unblockRef.current = history?.block((location) => {
      if (openPrompt && location?.pathname !== currentLocation?.pathname) {
        setCurrentPath(location?.pathname);
        setShowPrompt(true);
        return false;
      }
      return true;
    });
    return () => {
      unblockRef?.current();
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPrompt]);

  const handleOK = async () => {
    if (handleContinue) {
      await handleContinue();
    }
    if (unblockRef) {
      unblockRef?.current();
    }
    setShowPrompt(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false
    });
    history?.push(currentPath, pageFilters);
  };

  const handleCancel = () => {
    if (isFunction(onCancel)) {
      onCancel();
    }
    setShowPrompt(false);
  };

  return showPrompt ? (
    <div id="router-prompt">
      <Modal
        open={showPrompt}
        footer={null}
        closable={false}
        maskClosable={false}
        centered
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('router-prompt')
        }
      >
        <Title level={4}>{title}</Title>
        <p>{description}</p>
        <div className="d-flex button-section">
          <Space>
            <Button
              type="text"
              htmlType="submit"
              className="text-btn mr-8"
              size="middle"
              onClick={handleOK}
            >
              {okText}
            </Button>
            <Button type="text" className="text-btn2" onClick={handleCancel}>
              {cancelText}
            </Button>
          </Space>
        </div>
      </Modal>
    </div>
  ) : null;
}
