import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as urlSlug from 'url-slug';
import { GET_WORKSPACES_APPS } from '../../../../../app/components/sidebar/graphql/Queries';
import { MAX_LENGTHS, PAGE_TYPES } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import { SlugInput } from '../../../../labels/topics/components/FormInputs';
import { Select } from '../../../../videos/components/FormInputs';
import { useEditPage } from '../../../context/EditPageProvider';
import { UPDATE_PAGE } from '../../../graphql/Mutations';
import SelectGroup from '../../groups/SelectGroup';
import { Permissions } from './FormInputs';
import SeoInput from '../../../../../components/SeoInput';

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

const Settings = () => {
  const {
    setSelectionType,
    settings,
    fetchingPageDetails,
    pageType,
    refetchPageDetails
  } = useEditPage();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...settings,
      permissions: settings?.permissions?.map((value) => ({
        label: value,
        value
      }))
    });
  }, [settings, form]);

  const { id } = useParams();

  const [updatePage, { loading }] = useMutation(UPDATE_PAGE);

  const handleSubmit = ({ slug, apps, group, ...values }) => {
    const appIds = apps?.map(({ value }) => value);
    const payload = {
      ...values,
      permissions: values?.permissions?.map(({ value }) => value),
      ...(settings?.allowDelete && {
        slug: slug.substring(1)
      }),
      appIds,
      pageGroupId: group?.value || null
    };
    updatePage({
      variables: {
        data: payload,
        where: { id }
      }
    })
      .then(() => {
        setSelectionType('');
        refetchPageDetails();
      })
      .catch();
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const handleCancel = () => {
    setSelectionType('');
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={settings}
      disabled={fetchingPageDetails}
    >
      <Form.Item
        label="Title"
        name="title"
        required
        rules={[
          formValidatorRules?.required('Please enter title!'),
          formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
        ]}
      >
        <Input placeholder="Enter title" onChange={handleTitleChange} />
      </Form.Item>
      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          {
            required: true,
            message: 'Please enter slug!'
          },
          formValidatorRules?.maxLength(MAX_LENGTHS.TITLE),
          formValidatorRules?.slug()
        ]}
      >
        <SlugInput
          readOnly={!settings?.allowDelete}
          disabled={!settings?.allowDelete}
        />
      </Form.Item>
      {pageType === PAGE_TYPES.CUSTOM && (
        <Form.Item label="Apps" name="apps">
          <Select
            multiple
            placeholder="Select apps"
            query={GET_WORKSPACES_APPS}
            variablesSelector={variablesSelector}
            dataSelector={(data) => {
              return (
                data?.workspaceApps?.workspaceApps?.map(
                  ({ id: key, name }) => ({
                    label: name,
                    value: key
                  })
                ) ?? []
              );
            }}
            keys={{
              data: 'workspaceApps',
              records: 'workspaceApps',
              count: 'count'
            }}
          />
        </Form.Item>
      )}
      <Form.Item name="group" label="Page Group">
        <SelectGroup />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>

      <Form.Item
        name="metaHeader"
        label="Meta Header"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea rows={5} placeholder="Enter meta header" />
      </Form.Item>

      <Form.Item
        name="metaFooter"
        label="Meta Footer"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea rows={5} placeholder="Enter meta footer" />
      </Form.Item>
      <Permissions />
      <SeoInput form={form} />
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              disabled={fetchingPageDetails || loading}
              loading={loading}
              htmlType="submit"
              className="text-btn mr-8"
              type="text"
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="text"
              disabled={fetchingPageDetails || loading}
              className="text-btn2"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default Settings;
