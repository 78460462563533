import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Radio, Row, Space, Upload } from 'antd';
import { isArray, isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  DOMAIN_STATUS,
  MAX_LENGTHS,
  PROVIDER_TYPES,
  VIDEO_UPLOAD_PLATFORMS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { formValidatorRules, uploadToPlatform } from '../../../common/utils';
import ProgressBar from '../../../components/ProgressBar';
import RouterPrompt from '../../../components/RouterPrompt';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { Select, SelectPlatForm } from '../../videos/components/FormInputs';
import {
  CREATE_VIDEO_ASSET,
  IMPORT_PLATFORM_VIDEO_TO_WORKSPACE_ASSET,
  UPDATE_ASSET_STATUS,
  UPDATE_VIDEO_ASSET_STATUS
} from '../graphql/Mutations';
import { GET_LANGUAGES } from '../graphql/Queries';

const { TextArea } = Input;
const languageVariableSelctor = ({ skip, limit, search }) => ({
  filter: {
    skip,
    limit,
    search
  }
});

export const SelectLanguage = ({ ...rest }) => {
  return (
    <Select
      popupMatchSelectWidth={false}
      variablesSelector={languageVariableSelctor}
      placeholder="Select language"
      query={GET_LANGUAGES}
      dataSelector={(data) =>
        data?.languages?.languages?.map(({ code, name }) => ({
          label: name,
          value: code
        })) ?? []
      }
      keys={{
        data: 'languages',
        records: 'languages',
        count: 'count'
      }}
      queryOptions={{
        fetchPolicy: 'cache-first'
      }}
      {...rest}
    />
  );
};

const CREATE_VIDEO_ASSET_TYPE = {
  FILE_UPLOAD: 'FILE_UPLOAD',
  IMPORT_FROM_URL: 'IMPORT_FROM_URL'
};

const CREATE_VIDEO_TYPE_OPTIONS = [
  {
    label: 'File Upload',
    value: CREATE_VIDEO_ASSET_TYPE.FILE_UPLOAD
  },
  {
    label: 'Import From URL',
    value: CREATE_VIDEO_ASSET_TYPE.IMPORT_FROM_URL
  }
];

const AddVideoModalForm = ({
  isModal,
  setShowModal,
  setNewAsset,
  closeModal,
  setProgressLoading
}) => {
  const [form] = Form.useForm();
  const videoUrl = Form.useWatch('videoUrl', form);
  const providerVideo = Form.useWatch('providerVideo', form);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [videoProgress, setVideoProgress] = useState(undefined);
  const [showPrompt, setShowPrompt] = useState(false);
  const type = Form.useWatch('type', form);

  const [createVideoAssetMutate] = useMutation(CREATE_VIDEO_ASSET, {
    onError() {
      setButtonLoading(false);
      if (isModal) {
        setProgressLoading(false);
      }
    }
  });

  const [createPlatformVideoAssetMutate] = useMutation(
    IMPORT_PLATFORM_VIDEO_TO_WORKSPACE_ASSET,
    {
      onError() {
        setButtonLoading(false);
        if (isModal) {
          setProgressLoading(false);
        }
      }
    }
  );

  const [updateVideoAssetStatus] = useMutation(UPDATE_VIDEO_ASSET_STATUS);

  const [updateAssetStatus] = useMutation(UPDATE_ASSET_STATUS, {
    onError: () => {}
  });

  useEffect(() => {
    if (videoProgress >= 0 || videoUrl) {
      setShowPrompt(true);
    }
  }, [videoProgress, videoUrl]);

  const onFinish = async (values) => {
    setShowPrompt(true);
    if (isFunction(setNewAsset)) {
      setNewAsset(null);
    }
    setButtonLoading(true);
    if (isModal) {
      setProgressLoading(true);
    }
    const { video, platform } = values;

    // CMP-1325 : according to this task we have changed the flow. first we create asset then upload the respective file to the platform.
    try {
      const textData = {
        title: values?.title,
        description: values?.description,
        languageCode: values?.languageCode?.value
      };
      const videofile = video?.[0]?.originFileObj;
      const platformId = values?.providerVideo?.platformId;
      if (platformId) {
        const response = await createPlatformVideoAssetMutate({
          variables: {
            where: {
              platformId,
              videoId: values?.providerVideo?.id
            },
            data: {
              ...textData
            }
          }
        });
        if (response?.data?.importPlatformVideoToWorkspaceAsset) {
          setVideoProgress(undefined);
          setButtonLoading(false);

          if (isModal) {
            setShowModal(false);
            setNewAsset({ asset: false, data: null });
            setProgressLoading(false);
            closeModal();
          }
          return;
        }
      }
      const importUrl = values?.videoUrl;
      const response = await createVideoAssetMutate({
        variables: {
          data: {
            ...textData,
            ...(importUrl
              ? { importUrl }
              : {
                  contentLength: videofile.size,
                  fileName: `${videofile.name.replace(/\s/g, '_')}`
                }),
            platformId: platform?.value
          }
        }
      });
      if (importUrl) {
        setVideoProgress(undefined);
        setButtonLoading(false);

        if (isModal) {
          const asset = response?.data?.createVideoAsset?.asset;
          if (asset) {
            setNewAsset({ asset: true, data: asset });
            closeModal();
          }
          setShowPrompt(false);
          setProgressLoading(false);
          setShowModal(false);
        }
        return;
      }
      setVideoProgress(undefined);
      if (response?.data?.createVideoAsset?.signedUrl && videofile) {
        const res = await uploadToPlatform(
          response?.data?.createVideoAsset?.signedUrl,
          videofile,
          setVideoProgress,
          platform?.provider?.key
        ).catch(async (err) => {
          await updateAssetStatus({
            variables: {
              where: {
                id: response?.data?.createVideoAsset?.asset?.id
              },
              data: {
                errorObj: JSON.parse(err),
                status: DOMAIN_STATUS.ERRORED
              }
            }
          });
          setVideoProgress(undefined);
          setButtonLoading(false);
          if (isModal) {
            setProgressLoading(false);
          }
        });
        if (res && response?.data?.createVideoAsset?.asset?.id) {
          if (platform?.provider?.key === VIDEO_UPLOAD_PLATFORMS.PEERTUBE) {
            const data = JSON.parse(res);
            await updateVideoAssetStatus({
              variables: {
                peertubeUUID: data?.video?.uuid,
                id: response?.data?.createVideoAsset?.asset?.id,
                platformId: platform?.value
              }
            });
          }
          if (isModal) {
            const asset = response?.data?.createVideoAsset?.asset;
            setShowModal(false);
            setNewAsset({ asset: true, data: asset });
            closeModal();
            setProgressLoading(false);
          }
          setVideoProgress(undefined);
          setButtonLoading(false);
        } else {
          if (isModal) {
            const asset = response?.data?.createVideoAsset?.asset;
            setShowModal(false);
            setNewAsset({ asset: true, data: asset });
            setProgressLoading(false);
            closeModal();
          }
          setVideoProgress(undefined);
          setButtonLoading(false);
        }
      }
      form?.resetFields();
    } catch (error) {
      if (isModal) {
        setProgressLoading(false);
      }
      setVideoProgress(undefined);
      setButtonLoading(false);

      return error;
    }
  };

  const handlePromptBtnChange = () => {
    setShowPrompt(false);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const initialValues = {
    languageCode: {
      label: 'English',
      value: 'en'
    },
    providerVideo: {
      id: '',
      title: '',
      description: '',
      imageURL: '',
      platformId: ''
    },
    videoUrl: '',
    type: CREATE_VIDEO_ASSET_TYPE.FILE_UPLOAD
  };

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        name="create-asset"
        layout="vertical"
        className="add-edit-form create-asset-modal"
        onFinish={onFinish}
        disabled={isViewOnly}
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input
                placeholder="Enter title"
                disabled={isViewOnly || buttonLoading}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <TextArea
                rows={2}
                placeholder="Enter description"
                disabled={isViewOnly || buttonLoading}
              />
            </Form.Item>
            <Form.Item name="languageCode" label="Language Code">
              <SelectLanguage />
            </Form.Item>
          </Col>
          <Col span={12}>
            <fieldset className="mb-12">
              <legend className="role-legend">Upload Video File</legend>
              <Form.Item name="type" label="Choose type">
                <Radio.Group size="middle">
                  {CREATE_VIDEO_TYPE_OPTIONS.map(({ value, label }) => (
                    <Radio value={value} key={value}>
                      {label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="platform"
                label="Service Provider"
                rules={[
                  {
                    required: !providerVideo?.id,
                    message: 'Service Provider is required'
                  }
                ]}
              >
                <SelectPlatForm type={PROVIDER_TYPES.VIDEO} fullWidth />
              </Form.Item>
              {type === CREATE_VIDEO_ASSET_TYPE.FILE_UPLOAD && (
                <Form.Item
                  name="video"
                  label="Upload Video"
                  required={!videoUrl && !providerVideo?.id}
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.length && !videoUrl && !providerVideo?.id) {
                          throw new Error('Please select video');
                        }
                      }
                    }
                  ]}
                  getValueFromEvent={(e) => {
                    if (isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                  valuePropName="fileList"
                >
                  <Upload
                    maxCount={1}
                    disabled={isViewOnly || buttonLoading}
                    accept=".mp4"
                    beforeUpload={() => false}
                    onChange={() =>
                      form.resetFields(['providerVideo', 'videoUrl'])
                    }
                  >
                    <Space>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      <span>(.mp4)</span>
                    </Space>
                  </Upload>
                </Form.Item>
              )}
              {type === CREATE_VIDEO_ASSET_TYPE.IMPORT_FROM_URL && (
                <Form.Item name="videoUrl" label="Import Video">
                  <Input
                    placeholder="Enter url"
                    disabled={isViewOnly || buttonLoading}
                    onChange={() =>
                      form.resetFields(['providerVideo', 'video'])
                    }
                  />
                </Form.Item>
              )}
              {videoProgress >= 0 && (
                <Form.Item>
                  <ProgressBar progress={videoProgress} />
                </Form.Item>
              )}
            </fieldset>
          </Col>
        </Row>
        <div className="d-flex button-section justify-end">
          <Space>
            {isAddEditAllowed && (
              <Form.Item>
                <Button
                  loading={buttonLoading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
              </Form.Item>
            )}
          </Space>
        </div>
        <RouterPrompt
          openPrompt={showPrompt}
          onCancel={handlePromptBtnChange}
          handleContinue={handlePromptBtnChange}
        />
      </Form>
    </>
  );
};

export default AddVideoModalForm;
