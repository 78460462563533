import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Divider, Form, Input, Space, Typography } from 'antd';
import { isEmpty, map, omit } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  MESSAGE,
  PERMISSION_TYPE,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { normalizeIntegerValue } from '../../../common/utils';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import { GET_UI_LABELS } from '../../ui-labels/graphql/Queries';
import { Select } from '../../videos/components/FormInputs';
import { UPDATE_PLAN_PERMISSIONS } from '../graphql/Mutations';
import { GET_SUBSCRIPTION_PLAN } from '../graphql/Queries';
import AddPermissionModal from './AddPermissionModal';

const { Title } = Typography;

const initialValues = {
  permissions: {
    allowed: true,
    applyLimit: false,
    limit: 0,
    frequency: 0,
    errorMessage: null,
    limitMessage: null
  }
};

const RightPanelPermissions = ({
  form,
  permissionKey,
  planId,
  allPermissions,
  setAllPermissions,
  permissionsValues,
  allowedPermission,
  loading,
  setAllowedPermission,
  permissionsLoading,
  setPermissionLoading,
  permissionData,
  structuredPayload,
  setStructuredPayload
}) => {
  const newLabelRef = useRef(null);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const applyLimit = Form?.useWatch(['permissions', 'applyLimit'], form);
  const [
    updatePlanPermissions,
    { loading: submitPermissionLoading }
  ] = useMutation(UPDATE_PLAN_PERMISSIONS, {
    onError() {}
  });

  const [getSubscriptionPlan] = useLazyQuery(GET_SUBSCRIPTION_PLAN, {
    onCompleted(res) {
      setAllowedPermission(res?.subscriptionPlan?.applyPermissions);
      if (res?.subscriptionPlan?.permissions?.length > 0) {
        setAllPermissions(
          map(res?.subscriptionPlan?.permissions, (item) => ({
            key: item?.key,
            frequency: Number(item?.frequency ?? 0),
            limit: Number(item?.limit ?? 0),
            errorMessage: item?.errorMessage ?? '',
            limitMessage: item?.limitMessage ?? '',
            errorTitle: item?.errorTitle ?? '',
            limitTitle: item?.limitTitle ?? '',
            allowed: item?.allowed,
            applyLimit: item?.applyLimit
          }))
        );
        const mergedArray = structuredPayload?.map((defaultItem) => {
          const matchedItem = res?.subscriptionPlan?.permissions.find(
            (responseItem) => responseItem?.key === defaultItem?.key
          );
          return matchedItem || defaultItem;
        });
        const structuredData = map(mergedArray, (item) =>
          omit(
            {
              key: item?.key,
              frequency: Number(item?.frequency ?? 0),
              limit: Number(item?.limit ?? 0),
              errorMessage: item?.errorMessage ?? '',
              limitMessage: item?.limitMessage ?? '',
              errorTitle: item?.errorTitle ?? '',
              limitTitle: item?.limitTitle ?? '',
              allowed: item?.allowed,
              applyLimit: item?.applyLimit
            },
            'type'
          )
        );
        setStructuredPayload(structuredData);
      } else {
        const structuredData = map(permissionData, (item) =>
          omit(
            {
              key: item?.key,
              allowed: true,
              applyLimit: false,
              limit: 0,
              frequency: 0,
              errorMessage: null,
              limitMessage: null,
              errorTitle: null,
              limitTitle: null
            },
            'type'
          )
        );
        setAllPermissions(structuredData);
      }
    },
    fetchPolicy: 'network-only',
    onError() {}
  });

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  function replaceObjectByKey(array, keyToMatch, newObject) {
    return array?.map((obj) => (obj?.key === keyToMatch ? newObject : obj));
  }

  const handleFinish = (values) => {
    setPermissionLoading(true);
    const currentPermissionValue = {
      key: permissionKey,
      allowed: values?.permissions?.allowed,
      applyLimit: values?.permissions?.applyLimit,
      frequency: Number(values?.permissions?.frequency ?? 0),
      limit: Number(values?.permissions?.limit ?? 0),
      errorMessage: values?.permissions?.errorMessage?.value ?? '',
      limitMessage: values?.permissions?.limitMessage?.value ?? '',
      errorTitle: values?.permissions?.errorTitle?.value ?? '',
      limitTitle: values?.permissions?.limitTitle?.value ?? ''
    };
    const payload = replaceObjectByKey(
      structuredPayload,
      permissionKey,
      currentPermissionValue
    );
    if (payload && planId) {
      setAllPermissions(payload);
      updatePlanPermissions({
        variables: {
          data: {
            applyPermissions: allowedPermission,
            permissions: payload
          },
          where: {
            id: planId
          }
        },
        onCompleted: () => {
          setPermissionLoading(false);
          if (planId) {
            getSubscriptionPlan({
              variables: {
                where: {
                  id: planId
                }
              }
            });
          }
        },
        onError: () => {
          setPermissionLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (planId) {
      getSubscriptionPlan({
        variables: {
          where: {
            id: planId
          }
        }
      });
    }
  }, [planId]);

  useEffect(() => {
    if (allPermissions && permissionKey) {
      allPermissions?.map((permission) => {
        const isMatched = permission?.key === permissionKey;
        if (isMatched) {
          form.setFieldsValue({
            permissions: {
              allowed: permission?.allowed,
              applyLimit:
                permission?.applyLimit &&
                permissionsValues?.[permissionKey]?.permissionType ===
                  PERMISSION_TYPE.WRITE,
              limit: permission?.limit ?? 0,
              frequency: permission?.frequency ?? 0,
              permissionType: permission?.permissionType,
              errorMessage: isEmpty(permission?.errorMessage)
                ? null
                : {
                    label: permission?.errorMessage,
                    value: permission?.errorMessage
                  },
              limitMessage: isEmpty(permission?.limitMessage)
                ? null
                : {
                    label: permission?.limitMessage,
                    value: permission?.limitMessage
                  },
              errorTitle: isEmpty(permission?.errorTitle)
                ? null
                : {
                    label: permission?.errorTitle,
                    value: permission?.errorTitle
                  },
              limitTitle: isEmpty(permission?.limitTitle)
                ? null
                : {
                    label: permission?.limitTitle,
                    value: permission?.limitTitle
                  }
            }
          });
        }
        return permission;
      });
    }
  }, [permissionKey, allPermissions]);

  return (
    <>
      <div className="m-24 permission-form-wrapper">
        <Form
          layout="vertical"
          onFinish={handleFinish}
          form={form}
          initialValues={initialValues}
          disabled={submitPermissionLoading}
        >
          <Form.Item name={['permissions', 'allowed']} valuePropName="checked">
            <Switch label="Allow" />
          </Form.Item>
          <Divider />
          <Title className="mb-8" level={4}>
            Display following to the user if not allowed :
          </Title>
          <Form.Item
            name={['permissions', 'errorTitle']}
            label="Error Title"
            rules={[{ required: true, message: 'Please select error title!' }]}
          >
            <Select
              placeholder="Select title"
              query={GET_UI_LABELS}
              fetchPolicy="cache-first"
              variablesSelector={(filter) => ({
                filter: { labelType: MESSAGE, ...filter }
              })}
              dataSelector={(data) =>
                data?.uiLabelsAdmin?.uiLabels?.map(({ key }) => ({
                  label: key,
                  value: key
                })) ?? []
              }
              keys={{
                data: 'uiLabelsAdmin',
                records: 'uiLabels',
                count: 'count'
              }}
              ref={newLabelRef}
              dropdownRender={(menu) => {
                return (
                  <div
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Button
                      className="select-add-btn"
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsPermissionModalOpen(true);
                      }}
                    >
                      Add New
                    </Button>
                    <div className="header-dropdown">{menu}</div>
                  </div>
                );
              }}
            />
          </Form.Item>
          <Form.Item
            name={['permissions', 'errorMessage']}
            label="Error Message"
            rules={[
              { required: true, message: 'Please select error message!' }
            ]}
          >
            <Select
              placeholder="Select message"
              query={GET_UI_LABELS}
              variablesSelector={(filter) => ({
                filter: { labelType: MESSAGE, ...filter }
              })}
              fetchPolicy="cache-first"
              dataSelector={(data) =>
                data?.uiLabelsAdmin?.uiLabels?.map(({ key }) => ({
                  label: key,
                  value: key
                })) ?? []
              }
              keys={{
                data: 'uiLabelsAdmin',
                records: 'uiLabels',
                count: 'count'
              }}
              ref={newLabelRef}
              dropdownRender={(menu) => {
                return (
                  <div
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Button
                      className="select-add-btn"
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsPermissionModalOpen(true);
                      }}
                    >
                      Add New
                    </Button>
                    <div className="header-dropdown">{menu}</div>
                  </div>
                );
              }}
            />
          </Form.Item>
          <Divider />
          <Form.Item
            name={['permissions', 'applyLimit']}
            valuePropName="checked"
          >
            <Switch
              label="Apply Limit Usage"
              disabled={
                permissionsValues?.[permissionKey]?.permissionType ===
                PERMISSION_TYPE.READ
              }
            />
          </Form.Item>
          {applyLimit &&
            permissionsValues?.[permissionKey]?.permissionType ===
              PERMISSION_TYPE.WRITE && (
              <>
                <Divider />
                <Form.Item
                  name={['permissions', 'frequency']}
                  label="Limited Permission Renewal Period (Days)"
                  normalize={normalizeIntegerValue}
                >
                  <Input
                    type="number"
                    placeholder="Enter frequency"
                    className="number-input"
                  />
                </Form.Item>
                <Form.Item
                  name={['permissions', 'limit']}
                  label="Maximum Uses Allowed per Period"
                  normalize={normalizeIntegerValue}
                >
                  <Input
                    placeholder="Enter limit"
                    type="number"
                    className="number-input"
                  />
                </Form.Item>
                <Title className="mb-8" level={4}>
                  Display following to the user if limit has been exceeded :
                </Title>
                <Form.Item
                  name={['permissions', 'limitTitle']}
                  label="Exceeded Limit Error Title"
                  rules={[
                    { required: true, message: 'Please select limit title!' }
                  ]}
                >
                  <Select
                    placeholder="Select title"
                    query={GET_UI_LABELS}
                    fetchPolicy="cache-first"
                    variablesSelector={(filter) => ({
                      filter: { labelType: MESSAGE, ...filter }
                    })}
                    dataSelector={(data) =>
                      data?.uiLabelsAdmin?.uiLabels?.map(({ key }) => ({
                        label: key,
                        value: key
                      })) ?? []
                    }
                    keys={{
                      data: 'uiLabelsAdmin',
                      records: 'uiLabels',
                      count: 'count'
                    }}
                    ref={newLabelRef}
                    dropdownRender={(menu) => {
                      return (
                        <div
                          onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <Button
                            className="select-add-btn"
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsPermissionModalOpen(true);
                            }}
                          >
                            Add New
                          </Button>
                          <div className="header-dropdown">{menu}</div>
                        </div>
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={['permissions', 'limitMessage']}
                  label="Exceeded Limit Error Message"
                  rules={[
                    {
                      required: true,
                      message: 'Please select limit message!'
                    }
                  ]}
                >
                  <Select
                    placeholder="Select message"
                    query={GET_UI_LABELS}
                    fetchPolicy="cache-first"
                    variablesSelector={(filter) => ({
                      filter: { labelType: MESSAGE, ...filter }
                    })}
                    dataSelector={(data) =>
                      data?.uiLabelsAdmin?.uiLabels?.map(({ key }) => ({
                        label: key,
                        value: key
                      })) ?? []
                    }
                    keys={{
                      data: 'uiLabelsAdmin',
                      records: 'uiLabels',
                      count: 'count'
                    }}
                    ref={newLabelRef}
                    dropdownRender={(menu) => {
                      return (
                        <div
                          onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <Button
                            className="select-add-btn"
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsPermissionModalOpen(true);
                            }}
                          >
                            Add New
                          </Button>
                          <div className="header-dropdown">{menu}</div>
                        </div>
                      );
                    }}
                  />
                </Form.Item>
              </>
            )}
          <div className="d-flex button-section mb-8">
            <Space>
              {isAddEditAllowed && (
                <Button
                  disabled={
                    permissionsLoading || loading || submitPermissionLoading
                  }
                  loading={
                    permissionsLoading || loading || submitPermissionLoading
                  }
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
              )}
            </Space>
          </div>
        </Form>
        {isPermissionModalOpen && (
          <AddPermissionModal
            isPermissionModalOpen={isPermissionModalOpen}
            setIsPermissionModalOpen={setIsPermissionModalOpen}
            onCreate={() => newLabelRef?.current?.refetch()}
          />
        )}
      </div>
    </>
  );
};

export default RightPanelPermissions;
