import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULE_TYPES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import HtmlEditor from '../../../../../components/HtmlEditor';
import SelectAsset from '../../../../assets/components/SelectAsset';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { initialAssetValues } from './CTAForm';
import {
  KeyInput,
  ModuleFields,
  Permissions,
  SelectPlan,
  ShowFields
} from './FormInputs';

const initialSubscriptionPlan = {
  html: '',
  asset: { ...initialAssetValues },
  plan: null
};

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  config: {
    asset: { ...initialAssetValues },
    key: '',
    subscriptionPlans: []
  },
  settings: {
    action: true,
    description: true,
    image: true,
    previewTimestamp: true,
    title: true
  }
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.SUBSCRIPTION_BLOCK,
      MODULE_TYPES.SUBSCRIPTION_HEADER,
      MODULE_TYPES.SUBSCRIPTION_LIST
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.SUBSCRIPTION_BLOCK,
      MODULE_TYPES.SUBSCRIPTION_LIST
    ]
  },
  {
    name: 'image',
    label: 'Image',
    allowedTypes: [MODULE_TYPES.SUBSCRIPTION_BLOCK]
  },
  {
    name: 'previewTimestamp',
    label: 'Preview Timestamp',
    allowedTypes: [MODULE_TYPES.SUBSCRIPTION_BLOCK]
  },
  {
    name: 'action',
    label: 'Action Button',
    allowedTypes: [
      MODULE_TYPES.SUBSCRIPTION_BLOCK,
      MODULE_TYPES.SUBSCRIPTION_HEADER
    ]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.SUBSCRIPTION_BLOCK]: 'subscriptionBlockModule',
  [MODULE_TYPES.SUBSCRIPTION_HEADER]: 'subscriptionHeaderModule',
  [MODULE_TYPES.SUBSCRIPTION_LIST]: 'subscriptionListModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.SUBSCRIPTION_BLOCK]: 'Subscription Block Configs',
  [MODULE_TYPES.SUBSCRIPTION_HEADER]: 'Subscription Header Configs',
  [MODULE_TYPES.SUBSCRIPTION_LIST]: 'Subscription List Configs'
};

const SubscriptionPlanInput = ({
  name,
  remove,
  defaultValues,
  ...restField
}) => {
  return (
    <>
      <Form.Item label="Plan" name={[name, 'plan']} {...restField}>
        <SelectPlan
          alreadySelectedPlan={
            defaultValues?.moduleData?.config?.subscriptionPlans || []
          }
        />
      </Form.Item>
      <Form.Item label="Html" name={[name, 'html']} {...restField}>
        <HtmlEditor className="full-html" height={400} />
      </Form.Item>
      <Form.Item label="Image" name={[name, 'asset']} {...restField}>
        <SelectAsset
          modalTitle="Select Image"
          categoryKey={ASSET_CATEGORY.IMAGE}
          btnText="Image"
          dataSelector={({ id, url }) => ({
            id,
            url
          })}
        />
      </Form.Item>
      <Button
        className="text-btn mb-12"
        type="text"
        size="small"
        onClick={() => remove(name)}
      >
        Remove Plan
      </Button>
    </>
  );
};

const SubscriptionForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        ...(type === MODULE_TYPES.SUBSCRIPTION_BLOCK && {
          config: {
            asset: defaultValues?.moduleData?.config?.image ?? {
              ...initialAssetValues
            }
          }
        }),
        ...(type === MODULE_TYPES.SUBSCRIPTION_LIST && {
          config: {
            key: defaultValues?.moduleData?.config?.key ?? '',
            subscriptionPlans:
              defaultValues?.moduleData?.config?.subscriptionPlans?.map(
                (plan) => ({
                  html: plan?.html ?? '',
                  asset:
                    plan?.images?.length > 0
                      ? plan?.images?.[0]
                      : { ...initialAssetValues },
                  plan: plan?.key
                    ? {
                        label: plan?.key,
                        value: plan?.key
                      }
                    : null
                })
              ) ?? []
          }
        })
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues, type]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const { asset, subscriptionPlans, ...restConfig } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        ...(type === MODULE_TYPES.SUBSCRIPTION_BLOCK && {
          config: {
            ...restConfig,
            imageId: asset?.id ?? null
          }
        }),
        ...(type === MODULE_TYPES.SUBSCRIPTION_LIST && {
          config: {
            ...restConfig,
            subscriptionPlans:
              subscriptionPlans?.map((plan) => ({
                key: plan?.plan?.value ?? '',
                imageId: plan?.asset?.id,
                html: plan?.html ?? ''
              })) ?? []
          }
        })
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        {[
          MODULE_TYPES.SUBSCRIPTION_BLOCK,
          MODULE_TYPES.SUBSCRIPTION_LIST
        ].includes(type) && (
          <Space className="w-full" direction="vertical">
            <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
            <div>
              {type === MODULE_TYPES.SUBSCRIPTION_BLOCK && (
                <Form.Item label="Image" name={['config', 'asset']}>
                  <SelectAsset
                    modalTitle="Select Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
              )}
              {type === MODULE_TYPES.SUBSCRIPTION_LIST && (
                <>
                  <Form.Item
                    label="Key"
                    name={['config', 'key']}
                    rules={[formValidatorRules?.maxLength(MAX_LENGTHS.KEY)]}
                  >
                    <KeyInput
                      disabled={isEdit}
                      readOnly={isEdit}
                      placeholder="Enter key"
                    />
                  </Form.Item>
                  <Typography.Text>Subscription Plans</Typography.Text>
                  <div className="mt-8">
                    <Form.List name={['config', 'subscriptionPlans']}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields?.map((field) => (
                            <SubscriptionPlanInput
                              remove={remove}
                              key={field?.key}
                              defaultValues={defaultValues}
                              {...field}
                            />
                          ))}
                          <Form.Item>
                            <Button
                              className="text-btn"
                              type="text"
                              onClick={() =>
                                add({ ...initialSubscriptionPlan })
                              }
                              icon={<PlusOutlined />}
                            >
                              Add Plan
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>
                </>
              )}
            </div>
          </Space>
        )}
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default SubscriptionForm;
