import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import { PencilSimpleLine, Trash } from 'phosphor-react';
import React, { useRef } from 'react';
import {
  ASSET_CATEGORY,
  MODULES,
  ROUTES,
  STATUS_COLORS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { getImageUrl, status } from '../../../common/utils';
import { modalContext } from '../../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import Image from '../../../components/Image';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import CustomCard from '../../component/card/Cscard';
import { DELETE_ASSET } from '../graphql/Mutations';
import { GET_ASSETS } from '../graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    limit,
    skip: offset,
    search,
    categoryKey: ASSET_CATEGORY?.ICON
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ assets }) => ({
  data: assets?.assets || [],
  count: assets?.count || 0
});

const columns = ({
  handlePreview,
  isPlatformAllowed,
  isViewOnly,
  handleEdit,
  isDeleteAllowed,
  handleDelete
}) => [
  {
    title: 'Icon',
    dataIndex: 'url',
    key: 'url',
    editable: true,
    width: '12%',
    render: (text, record) => (
      <Image
        blurHash={record?.blurhash}
        onClick={() => handlePreview(record)}
        className="group-img-table pointer"
        src={getImageUrl(text, { height: 50, width: 80 })}
        alt={record?.title ?? ''}
      />
    )
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: '22%'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '22%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '22%',
    render: (value) => (
      <Tag className="m-0 custom-tag" color={STATUS_COLORS[value]}>
        {status[value]}
      </Tag>
    )
  },
  ...(isPlatformAllowed || isDeleteAllowed
    ? [
        {
          title: 'Action',
          dataIndex: 'operation',
          width: '12%',
          render: (text, record) => (
            <div>
              {isPlatformAllowed && (
                <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Icon`}>
                  {isViewOnly ? (
                    <EyeFilled
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(record?.id);
                      }}
                    />
                  ) : (
                    <EditOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(record?.id);
                      }}
                    />
                  )}
                </Tooltip>
              )}
              {isDeleteAllowed && (
                <span onClick={(e) => e.stopPropagation()}>
                  <Tooltip title="Delete Icon">
                    <DeleteOutlined
                      onClick={() => {
                        handleDelete(record?.id);
                      }}
                    />
                  </Tooltip>
                </span>
              )}
            </div>
          )
        }
      ]
    : [])
];

function Icons({ history }) {
  const ref = useRef();
  const filterProps = useFilterBar();
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);
  const isPlatformAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const [deleteIcon] = useMutation(DELETE_ASSET, {
    onError() {}
  });

  const handleShowModal = () => {
    history?.push(`${ROUTES?.ASSETS_ICONS}/add`, { isUpdate: false });
  };

  const handleEdit = (id) => {
    history.push(`${ROUTES?.ASSETS_ICONS}/${id}/edit`);
  };

  const handlePreview = (item) => {
    if (ref.current.showPreview) {
      ref.current.showPreview({
        title: item.title,
        url: item.url,
        type: ASSET_CATEGORY.IMAGE
      });
    }
  };

  const handleDelete = async (id) => {
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this icon?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        deleteIcon({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (ref?.current?.removeItem && !errors) {
              ref?.current?.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  const iconsBreadcrumbs = [
    { label: MODULES?.ASSETS },
    { label: MODULES?.ICONS }
  ];
  return (
    <>
      <PageHeader
        menu={iconsBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isPlatformAllowed && isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Icons' }}
      />
      <PageList
        ref={ref}
        filters={filterProps.filters}
        listMode={filterProps.listMode}
        query={GET_ASSETS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (item) => (
            <CustomCard
              onClick={() => {
                handlePreview(item);
              }}
              alt={item?.title}
              src={item?.url}
              isIcon
              blurHash={item?.blurhash}
              status={[
                {
                  isTooltip: false,
                  status: status?.[item?.status],
                  statusColor: STATUS_COLORS?.[item?.status]
                }
              ]}
              title={moment(item?.createdAt).format('ll')}
              heading={item?.title}
              description={item?.description}
              primaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleEdit(item?.id);
              }}
              secondaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleDelete(item?.id);
              }}
              primary={
                isPlatformAllowed &&
                (isViewOnly ? (
                  <>
                    <EyeFilled size={16} />
                    View
                  </>
                ) : (
                  <>
                    <PencilSimpleLine size={16} />
                    Edit
                  </>
                ))
              }
              secondary={
                isDeleteAllowed && (
                  <>
                    <Trash size={16} />
                    Delete
                  </>
                )
              }
            />
          )
        }}
        TableProps={{
          columns: (props) =>
            columns({
              ...props,
              isPlatformAllowed,
              isViewOnly,
              isDeleteAllowed,
              handlePreview,
              handleEdit,
              handleDelete
            })
        }}
      />
    </>
  );
}

export default Icons;
