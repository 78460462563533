import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { CurrencyDollar } from 'phosphor-react';
import React, { useEffect } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  PLAN_FREQUENCY_OPTIONS,
  PLAN_TYPE_OPTIONS,
  REGEX,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import {
  formValidatorRules,
  normalizeNegativeNumber
} from '../../common/utils';
import LoadEditor from '../../components/LoadEditor';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import SelectAsset from '../assets/components/SelectAsset';
import SelectBadge from '../assets/components/SelectBadge';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import { CREATE_PLAN, UPDATE_PLAN } from './graphql/Mutations';
import { GET_SUBSCRIPTION_PLAN } from './graphql/Queries';

const AddEditPlans = ({ history, match: { params } }) => {
  const { planId } = params;
  const isEdit = !!planId;
  const [form] = Form.useForm();
  // commented for future use
  // const applyCustomAmount = Form.useWatch('allowCustomAmount', form);

  const subscriptionPlansBreadcrumbs = [
    { label: MODULES?.SUBSCRIPTIONS, route: ROUTES?.SUBSCRIPTIONS },
    planId && { label: planId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [getSubscriptionPlan, { loading: fetchingDetails }] = useLazyQuery(
    GET_SUBSCRIPTION_PLAN,
    {
      onCompleted(res) {
        if (res?.subscriptionPlan) {
          form?.setFieldsValue({
            ...res?.subscriptionPlan,
            amount: res?.subscriptionPlan?.amount?.usd?.price,
            // commented for future use
            // maxPrice: res?.subscriptionPlan?.amount?.usd?.maxPrice,
            imageIds: res?.subscriptionPlan?.images?.[0],
            badgeIds: res?.subscriptionPlan?.badges?.[0]
          });
        }
      },
      fetchPolicy: 'network-only',
      onError() {}
    }
  );

  const [
    createUpdateSubscriptionPlan,
    { loading: createLoading }
  ] = useMutation(isEdit ? UPDATE_PLAN : CREATE_PLAN, {
    onError() {}
  });

  useEffect(() => {
    if (planId) {
      getSubscriptionPlan({
        variables: {
          where: {
            id: planId
          }
        }
      });
    }
  }, [planId]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const normalizeAmount = (value) => {
    if (!value) {
      return null;
    }
    let newValue = value;
    newValue =
      newValue.indexOf('.') >= 0
        ? newValue.substr(0, newValue.indexOf('.')) +
          newValue.substr(newValue.indexOf('.'), 3)
        : newValue;

    return +newValue < 0 ? 0 : newValue;
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      order: Number(values?.order),
      weight: Number(values?.weight),
      applyPermissions: false,
      imageIds: values?.imageIds?.id && [values?.imageIds?.id],
      badgeIds: values?.badgeIds?.id && [values?.badgeIds?.id],
      amount: {
        usd: {
          price: Number(values?.amount)
          // commented for future use
          // maxPrice: Number(values?.maxPrice)
        }
      }
    };
    if (isEdit) {
      delete payload.key;
      delete payload.frequency;
      delete payload.type;
      delete payload.applyPermissions;
    }

    const res = await createUpdateSubscriptionPlan({
      variables: {
        // commented for future use
        // data: omit(payload, ['maxPrice']),
        data: payload,
        ...(isEdit && {
          where: {
            id: planId
          }
        })
      }
    });
    if (res?.data) {
      history?.push(ROUTES?.SUBSCRIPTIONS);
    }
  };

  const handleCancel = () => {
    history.push(ROUTES?.SUBSCRIPTIONS);
  };

  return (
    <>
      <PageHeader menu={subscriptionPlansBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form plan-form"
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              isActive: false,
              showOnPaywall: false,
              applyPermissions: false,
              allowCustomAmount: false
            }}
            disabled={isViewOnly || fetchingDetails}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Key"
                  name="key"
                  required
                  rules={[
                    {
                      validator(rule, value) {
                        if (!value) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject('Please enter a key!');
                        }
                        if (!REGEX?.KEY_VALUE?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject('Should be a valid key!');
                        }
                        return Promise?.resolve();
                      }
                    },
                    // need to set 100 as per requirement
                    formValidatorRules?.maxLength(100)
                  ]}
                >
                  <Input placeholder="Enter key" disabled={isEdit} />
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="name"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter name!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
                  ]}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item label="Image" name="imageIds">
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item label="Badge" name="badgeIds">
                  <SelectBadge
                    disabled={isViewOnly}
                    modalTitle="Select Badge"
                    btnText="Badge"
                    dataSelector={({ id, image: { url } }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="amount"
                  normalize={normalizeAmount}
                  label="Amount"
                  rules={[{ required: true, message: 'Please enter amount!' }]}
                >
                  <Input
                    prefix={<CurrencyDollar size={20} />}
                    type="number"
                    placeholder="Enter amount"
                    className="amount-input"
                  />
                </Form.Item>
                {/*  commented for future use */}
                {/* {applyCustomAmount && (
                  <Form.Item
                    name="maxPrice"
                    normalize={normalizeAmount}
                    label="Max Amount"
                    rules={[
                      { required: true, message: 'Please enter Max Amount!' }
                    ]}
                  >
                    <Input
                      prefix={<CurrencyDollar size={20} />}
                      type="number"
                      placeholder="Enter max amount"
                      className="amount-input"
                    />
                  </Form.Item>
                )} */}
                <Form.Item
                  name="order"
                  label="Order"
                  normalize={normalizeNegativeNumber}
                >
                  <Input
                    type="number"
                    placeholder="Enter order"
                    className="number-input"
                  />
                </Form.Item>
                <Form.Item
                  name="weight"
                  label="Weight"
                  normalize={normalizeNegativeNumber}
                >
                  <Input
                    type="number"
                    placeholder="Enter weight"
                    className="number-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Frequency"
                  name="frequency"
                  rules={[
                    !isEdit && {
                      required: true,
                      message: 'Please select frequency!'
                    }
                  ]}
                >
                  <Select
                    placeholder="Select frequency"
                    disabled={isEdit}
                    options={PLAN_FREQUENCY_OPTIONS}
                  />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[
                    !isEdit && {
                      required: true,
                      message: 'Please select type!'
                    }
                  ]}
                >
                  <Select
                    placeholder="Select type"
                    options={PLAN_TYPE_OPTIONS}
                    disabled={isEdit}
                  />
                </Form.Item>
                <Form.Item
                  className="m-0"
                  name="isActive"
                  valuePropName="checked"
                >
                  <Switch label="Active" />
                </Form.Item>
                <Form.Item
                  className="m-0"
                  name="showOnPaywall"
                  valuePropName="checked"
                >
                  <Switch label="Show on Paywall" />
                </Form.Item>
                {/* commented for future use */}
                {/* <Form.Item
                  className="m-0"
                  name="allowCustomAmount"
                  valuePropName="checked"
                >
                  <Switch label="Allow Custom Amount" />
                </Form.Item> */}
              </Col>
              <Col md={24} lg={12}>
                <Form.Item className="full-html" label="Html" name="html">
                  <LoadEditor disabled={isViewOnly} className="full-html" />
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={createLoading}
                    loading={createLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={createLoading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditPlans;
