import { useLazyQuery } from '@apollo/client';
import { Col, Input, Row } from 'antd';
import moment from 'moment';
import { DownloadSimple, Eye, MagnifyingGlass } from 'phosphor-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import videoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  IMPORT_VIDEO_DATE_FORMAT,
  PROVIDER_TYPES
} from '../../../common/constants';
import { ModalContent } from '../../../components/SelectableModal';
import CustomCard from '../../component/card/Cscard';
import { SelectPlatForm } from '../../videos/components/FormInputs';
import { GET_VIDEO_URL_BY_WORKSPACE_PLATFORM_VIDEO } from '../graphql/Queries';
import { ImportPlatformVideoModal } from './ImportVideo';

const ImportVideoModal = ({
  open,
  newAsset,
  platform,
  setPlatform,
  value,
  onChange,
  dataSelector,
  categoryKey,
  closeModal,
  handleCardSelect,
  setProgressLoading,
  progressLoading,
  ...rest
}) => {
  const [search, setSearch] = useState('');
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [platformData, setPlatformData] = useState({});

  useEffect(() => {
    if (!open) setSearch('');
  }, [open]);
  const [getVideoUrl] = useLazyQuery(
    GET_VIDEO_URL_BY_WORKSPACE_PLATFORM_VIDEO,
    {
      fetchPolicy: 'network-only'
    }
  );
  const handleSearchChange = (e) => {
    setSearch(e?.target?.value);
  };
  const handlePlatformChange = (newValue) => {
    setPlatform(newValue);
  };

  const filters = useMemo(
    () => ({ platform: platform ? platform?.value : '' }),
    [platform]
  );

  const handleImport = (data) => {
    setIsOpen(true);
    setPlatformData(data);
  };

  const handleSuccess = () => {
    setIsOpen(false);
    closeModal();
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleView = async (videoId) => {
    const { data } = await getVideoUrl({
      variables: {
        where: {
          videoId,
          platformId: platform?.value
        }
      }
    });
    if (
      data?.getVideoUrlByWorkspacePlatformAndVideoId &&
      ref.current.showPreview
    ) {
      ref.current.showPreview({
        title: data?.getVideoUrlByWorkspacePlatformAndVideoId.title,
        url: data?.getVideoUrlByWorkspacePlatformAndVideoId.url,
        type: ASSET_CATEGORY.VIDEO
      });
    }
  };
  return (
    <>
      <ImportPlatformVideoModal
        open={isOpen}
        isModal
        newAsset={newAsset}
        videoPlatformData={platformData}
        platform={platform}
        onClose={handleCancel}
        onSuccess={handleSuccess}
        setProgressLoading={setProgressLoading}
        progressLoading={progressLoading}
      />
      <Row className="mb-12">
        <Col xs={8}>
          <Input
            placeholder="Search..."
            allowClear
            className="search-component"
            name="search"
            value={search}
            onChange={handleSearchChange}
            prefix={<MagnifyingGlass size={20} />}
          />
        </Col>
        <Col xs={16} className="d-flex justify-end align-center">
          <SelectPlatForm
            type={PROVIDER_TYPES.VIDEO}
            onChange={handlePlatformChange}
            value={platform}
            setPlatform={setPlatform}
          />
        </Col>
      </Row>
      {open && (
        <ModalContent
          filters={filters}
          search={search}
          newAsset={newAsset}
          dataSelector={dataSelector}
          importRef={ref}
          renderItem={(item) => {
            return (
              <CustomCard
                alt={item?.title}
                src={
                  item?.videoThumbnailUrl ??
                  item?.imageThumbnailUrl ??
                  videoPlaceholder
                }
                title={moment(item?.createdAt).format(IMPORT_VIDEO_DATE_FORMAT)}
                heading={item?.title}
                description={item?.description}
                primaryOnClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  handleView(item?.id);
                }}
                secondaryOnClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  handleImport(item);
                }}
                primary={
                  <>
                    <Eye size={16} />
                    View
                  </>
                }
                secondary={
                  <>
                    <DownloadSimple size={16} />
                    Import
                  </>
                }
              />
            );
          }}
          {...rest}
        />
      )}
    </>
  );
};

export default ImportVideoModal;
